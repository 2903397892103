import React from "react";
import {
  Grid,
  Container,
  TextField,
  Typography,
  LinearProgress,
  Box,
  Paper,
  Button,
  Stack,
  Divider,
  Autocomplete,
  MenuItem,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  FormGroup,
  RadioGroup,
  Radio,
  InputAdornment,
  Dialog,
  Slide,
  DialogTitle,
  DialogContent,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery, gql, useMutation } from "@apollo/client";
import { Helmet } from "react-helmet";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import countries from "./countries.json";
import { IMAGES_API_URL } from "./constants";
import LogoPng from "./Assets/eventegration.png";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SearchModal from "./Components/SearchModal";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useMediaQuery } from "@mui/material";
import { useFeatures } from "./Hooks/GetFeatures";
import { usePaymentDetail } from "./Hooks/GetPaymentDetail";
import PaypalBtns from "./PaymentFolder/Paypal";
import PaymobBtns from "./PaymentFolder/Paymob";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import GppBadIcon from "@mui/icons-material/GppBad";
import Turnstile, { useTurnstile } from "react-turnstile";
import LinkNotFound from "./Components/LinkNotFound";
import useGeoLocation from "react-ipgeolocation";
import CustomRadioButton from "./Components/CustomRadioButton";
import EventClosed from "./Components/EventClosed";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import useCheckEmail from "./Hooks/useCheckEmail";
import OtpModal from "./Components/OtpModal";

export const DECRYPT_PMT = gql`
  query DecryptPmt($encryptedPmt: String!) {
    decryptPmt(encryptedPmt: $encryptedPmt)
  }
`;

export default function RegistrationPage() {
  const { link } = useParams();
  const { data, loading, error } = useQuery(GET_REG_STRUCTURE, {
    variables: { link },
    skip: !link,
  });
  const searchParams = new URLSearchParams(window.location.search);
  // console.log(searchParams);
  const encryptedPmt = searchParams.get("pmt");
  // console.log(">>>", encryptedPmt);

  const {
    data: pmtData,
    loading: pmtLoading,
    error: pmtError,
  } = useQuery(DECRYPT_PMT, {
    variables: { encryptedPmt },
    skip: !encryptedPmt,
  });

  const pmt = pmtData?.decryptPmt ? JSON.parse(pmtData.decryptPmt) : null;

  React.useEffect(() => {
    if (pmt && !pmt.isExhausted) {
      console.log("Decrypted PMT:", pmt);
      // return(
      //   <div>
      //     <p>Link Exhausted</p>
      //   </div>
      // )
    }
  }, [pmt]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleShareClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // console.log(data?.reg, error?.message);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const responseBoxRef = React.useRef(null);
  // console.log('eventID',data?.reg?.eventId);

  const { featureData, featureLoading } = useFeatures(
    data?.reg?.eventId,
    data?.reg?.role
  );

  const [response, setResponse] = React.useState("");
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const url = encodeURIComponent(window.location.href);
  const title = encodeURIComponent(data?.reg?.eventName || "Event");
  const linkText = "Check it out here";
  // console.log(link === "test-visitor-form");

  const image =
    link === "test-visitor-form"
      ? `${IMAGES_API_URL}banner/cse-landing-banner.jpg`
      : `${IMAGES_API_URL}banner/${data?.reg?.eventId}.jpeg`;
  let parsedShareContent = data?.reg?.shareContent
    ? JSON.parse(data?.reg?.shareContent)
    : null;
  let featuresList = [];
  if (featureData && featureData?.getFeatureList) {
    featuresList = JSON.parse(featureData?.getFeatureList.features);
  }

  let description = null;
  let encodedDescription = null;
  let urlRegex = null;
  let foundUrls = null;
  let extractedUrl = null;
  let twitterShareLink = null;
  let linkedinShareLink = null;
  let whatsappShareLink = null;
  let fbShareLink = null;

  if (featuresList.includes("ShareButton")) {
    description = parsedShareContent;
    encodedDescription = encodeURIComponent(description);
    urlRegex = /(https?:\/\/[^\s]+)/g;
    const foundUrls =
      Array.isArray(description) && description.length > 0
        ? description.match(urlRegex)
        : "";

    extractedUrl = foundUrls ? foundUrls[0] : null;

    twitterShareLink = `https://twitter.com/intent/tweet?text=${encodedDescription}`;
    linkedinShareLink = `https://www.linkedin.com/sharing/share-offsite/?url=${extractedUrl}`;
    whatsappShareLink = `https://api.whatsapp.com/send?text=${title} - ${encodedDescription}`;
    fbShareLink = `https://www.facebook.com/sharer/sharer.php?u=${extractedUrl}&quote=${encodedDescription}&picture=${image}`;
  }

  React.useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "273754185393849",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v11.0",
      });
    };
  }, []);

  const handleFBShare = () => {
    window.FB.ui(
      {
        method: "share_open_graph",
        action_type: "og.shares",
        action_properties: JSON.stringify({
          object: {
            "og:url": extractedUrl,
            "og:title": title,
            "og:description": description,
            "og:image": image,
          },
        }),
      },
      function (response) {
        console.log("response", response);
      }
    );
  };
  const handleReset = () => {
    setResponse("");
  };

  React.useEffect(() => {
    responseBoxRef?.current?.scrollIntoView();
  }, [response]);

  // console.log(">>>>>>>>>>",pmt)
  if (loading || featureLoading || pmtLoading) {
    return <LinearProgress />;
  }

  if (pmtError) {
    return (
      <Container maxWidth="sm" sx={{ textAlign: "center", mt: 8 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <ErrorOutlineIcon sx={{ fontSize: 60, color: "error.main", mb: 2 }} />
          <Typography variant="h4" gutterBottom>
            {pmtError.message || "Sponsor Link Not working"}
          </Typography>
          {/* <Typography variant="body1" paragraph>
            {error?.message || pmtError?.message || "An unexpected error occurred."}
          </Typography> */}
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            onClick={() => window.location.reload()}
          >
            Retry
          </Button>
        </Paper>
      </Container>
    );
  }
  if (pmtError) {
    window.alert(pmtError?.message || pmtError);
  }

  if (
    error?.message === "This registration page has been deactivated by admin"
  ) {
    return <EventClosed />;
  }
  if (!data?.reg?.eventId) {
    return <LinkNotFound />;
  }
  if (pmt && pmt.isExhausted) {
    return (
      <Container maxWidth="sm" sx={{ textAlign: "center", mt: 8 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <ErrorOutlineIcon
            sx={{ fontSize: 60, color: "warning.main", mb: 2 }}
          />
          <Typography variant="h4" gutterBottom>
            Registration Limit Reached
          </Typography>
          <Typography variant="body1" paragraph>
            We apologize, but the registration limit for this sponsor's link has
            been reached.
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {pmt.company ? `${pmt.company} has` : "The sponsor has"} exhausted
            the total allowed registrations for this link.
          </Typography>
          {/* <Button
            variant="contained"
            color="primary"
            sx={{ mt: 3 }}
            href={`/${pmt.link}`}
          >
            Return to Homepage
          </Button> */}
        </Paper>
      </Container>
    );
  }

  // if (error) {
  //   return (
  //     <Container>
  //       <Typography color="error" variant="h4">
  //         {error?.message}
  //         {/* <pre>{JSON.stringify(error, null, 2)}</pre> */}
  //       </Typography>
  //     </Container>
  //   );
  // }
  // console.log("?>>>>>>>>", data);

  return (
    <React.Fragment>
      <Helmet>
        <title>{data.reg.eventName}</title>
        <meta property="og:image" content={image} />
        <meta property="twitter:image" content={image} />
        <meta property="og:title" content="Event Registration System" />
        <meta
          property="og:description"
          content="An Event Registration System (ERS) is a digital platform designed to facilitate the process of enrolling attendees for events. It provides event organizers with tools to create event listings, manage attendee data, and handle payments. Key features often include"
        />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${IMAGES_API_URL}favicon/${data.reg.eventId}/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href={`${IMAGES_API_URL}favicon/${data.reg.eventId}/favicon-32x32.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${IMAGES_API_URL}favicon/${data.reg.eventId}/favicon-16x16.png`}
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href={`${IMAGES_API_URL}favicon/${data.reg.eventId}/favicon.ico`}
        />
        <link
          rel="manifest"
          href={`${IMAGES_API_URL}favicon/${data.reg.eventId}/site.webmanifest`}
        />
      </Helmet>
      <Container maxWidth="lg" sx={{ py: 3 }}>
        {data.reg.eventId && (
          <img
            src={
              link === "istc-cs-online-2024"
                ? `${IMAGES_API_URL}banner/cse-landing-banner.jpg`
                : `${IMAGES_API_URL}banner/${data.reg.eventId}.jpeg`
            }
            width="100%"
            alt="Banner"
            onError={(e) => {
              e.target.style.display = "none";
            }}
          />
        )}
        <Box p={3} />
        {!response && (
          <div
            dangerouslySetInnerHTML={{
              __html: data.reg.formHeading
                ? `<h4 style="text-align: center; margin-bottom: 16px;">${data.reg.formHeading}</h4>`
                : `<h4 style="text-align: center; margin-bottom: 16px; text-transform: capitalize;">${data.reg.role} Registration</h4>`,
            }}
          ></div>

        )}
        <Paper variant="outlined" sx={{ p: { xs: 1, md: 2 } }}>
          {response ? (
            <Stack spacing={1} ref={responseBoxRef}>
              <Typography>
                <div
                  dangerouslySetInnerHTML={{ __html: response }}
                  style={{ whiteSpace: "pre-line" }}
                />
              </Typography>
              <Box height={30} />
              <Stack direction="column" spacing={2}>
                {!featuresList.includes(
                  "Enable Another Registration Button"
                ) ? (
                  ""
                ) : (
                  <Box justifyContent={"center"} display="flex" width="100%">
                    <Button
                      onClick={handleReset}
                      variant="contained"
                      color="error"
                      size="large"
                    >
                      Another registration?
                    </Button>
                  </Box>
                )}

                {!featuresList.includes("ShareButton") ? (
                  ""
                ) : (
                  <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    flexWrap={"wrap"}
                    m={2}
                    divider={
                      <Divider
                        orientation={isSmallScreen ? "horizontal" : "vertical"}
                        flexItem
                      />
                    }
                    spacing={2}
                  >
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={12}
                        md={3}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          startIcon={<FacebookIcon />}
                          onClick={handleClose}
                          component="a"
                          href={fbShareLink}
                          target="_blank"
                          size="large"
                          sx={{
                            backgroundColor: "#1877F2",
                            color: "white",
                            "&:hover": { backgroundColor: "#145db2" },
                          }}
                        >
                          Share on Facebook
                        </Button>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={3}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          startIcon={<TwitterIcon />}
                          onClick={handleClose}
                          component="a"
                          href={twitterShareLink}
                          target="_blank"
                          size="large"
                          rel="noopener noreferrer"
                          sx={{
                            backgroundColor: "#1DA1F2",
                            color: "white",
                            "&:hover": { backgroundColor: "#1a8acb" },
                          }}
                        >
                          Share on Twitter
                        </Button>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={3}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          startIcon={<LinkedInIcon />}
                          onClick={handleClose}
                          component="a"
                          href={linkedinShareLink}
                          target="_blank"
                          size="large"
                          rel="noopener noreferrer"
                          sx={{
                            backgroundColor: "#0A66C2",
                            color: "white",
                            "&:hover": { backgroundColor: "#084a82" },
                          }}
                        >
                          Share on LinkedIn
                        </Button>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={3}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          startIcon={<WhatsAppIcon />}
                          onClick={handleClose}
                          component="a"
                          href={whatsappShareLink}
                          target="_blank"
                          size="large"
                          rel="noopener noreferrer"
                          sx={{
                            backgroundColor: "#25D366",
                            color: "white",
                            "&:hover": { backgroundColor: "#1ca851" },
                          }}
                        >
                          Share on WhatsApp
                        </Button>
                      </Grid>
                    </Grid>
                  </Stack>
                )}
              </Stack>
            </Stack>
          ) : (
            <RegForm
              fields={data.reg.fields}
              onSubmit={(res) => setResponse(res)}
              eventId={data.reg.eventId}
              role={data.reg.role}
              featuresList={featuresList}
              pmt={pmt || null}
            />
          )}
        </Paper>
        <Box
          sx={{
            py: 2,
            display: "flex",
            justifyContent: { xs: "center", md: "right" },
          }}
        >
          <Box>
            <Typography color="text.secondary" fontSize={12}>
              Powered by
            </Typography>
            <a href="http://eventegration.com">
              <img src={LogoPng} width={180} alt="Logo" />
            </a>
          </Box>
        </Box>
      </Container>
    </React.Fragment>
  );
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// export function RegForm({
//   fields,
//   onSubmit,
//   eventId,
//   role,
//   featuresList,
//   registrationId,
//   pmt,
// }) {
//   const [formState, setFormState] = React.useState({
//     openModal: false,
//     open: false,
//     clientId: null,
//     price: null,
//     selectedCurrency: null,
//     allData: null,
//     apiurl: null,
//     secretKey: null,
//     publicKey: null,
//     emailError: '',
//     selectedPaymentOption: '',
//     paymentFailedLoading: false,
//     token: null,
//     captchaOff: false,
//     selectedCountry: null,
//     selectedMobileCode: null,
//     isDefaultCountrySelected: false,
//     errorInPaymentInitialization: false,
//     isPopupNotOpened: false,
//     parsedRegistration: registrationId ? JSON.parse(registrationId) : null,
//   });

//   const navigate = useNavigate();
//   const { link } = useParams();
//   const turnstile = useTurnstile();
//   const { paymentData } = usePaymentDetail(eventId, role);
//   const { control, handleSubmit, setValue, watch, reset } = useForm({
//     defaultValues: fields.reduce((acc, field) => ({
//       ...acc,
//       [field.name]: field.type === "checkbox" ? !!parseInt(field.defaultValue || "0") : field.defaultValue || "",
//     }), {}),
//     resolver: yupResolver(/* your validation schema */),
//   });
//   const { country: geoCountry, error: geoError, isLoading: geoIsLoading } = useGeoLocation();

//   // console.log(">>>>>", geoCountry && typeof(geoCountry));

//   React.useEffect(() => {
//     if (!formState.isDefaultCountrySelected && geoCountry && featuresList.includes('GeoLocation')) {
//       const isdCode = countries.find((c) => c.code === geoCountry)
//       setFormState(prev => ({
//         ...prev,
//         selectedCountry: geoCountry,
//         isDefaultCountrySelected: true,
//         setSelectedMobileCode: isdCode?.dial_code,
//       }));

//     }
//   }, [geoCountry, formState.isDefaultCountrySelected]);

//   React.useEffect(() => {
//     if (link?.includes('survey')) {
//       navigate(`/survey/${link}`);
//     }
//   }, [link, navigate]);

//   const handleClickOpen = () => setFormState(prev => ({ ...prev, open: true }));
//   const handleModalOpen = () => setFormState(prev => ({ ...prev, openModal: true }));
//   const handleModalClose = () => setFormState(prev => ({ ...prev, openModal: false }));

//   const handleClose = () => {
//     setFormState(prev => ({
//       ...prev,
//       open: false,
//       errorInPaymentInitialization: false,
//       isPopupNotOpened: false,
//     }));
//     reset();
//   };

//   const handlePaymentSuccess = async (details) => {
//     if (details?.payment_status === "COMPLETED" || details?.payment_status === "success") {
//       handleClose();
//       const updatedAllData = { ...formState.allData, payment_status: "success" };
//       const reglink = pmt?.param || null;

//       const result = await doPaymentRegister({
//         variables: {
//           link: JSON.stringify({ link, pmtLink: reglink }),
//           jsonData: JSON.stringify(updatedAllData),
//           transaction: JSON.stringify(details),
//         },
//       }).then(res => res.data?.paymentRegister);

//       if (featuresList.includes('Captcha') && result) {
//         turnstile.reset();
//         setFormState(prev => ({ ...prev, token: null, captchaOff: true }));
//       }

//       return onSubmit(result);
//     }

//     if (details?. payment_status === "FAILED" || details?.payment_status === "failed") {
//       handleClose();
//       setFormState(prev => ({ ...prev, paymentFailedLoading: true }));
//     }
//   };
//   const findCountryCodeByDialCode = (dialCode) => {
//     // console.log("dial_code", dialCode);
//     const country = countries.find((c) => c.dial_code === dialCode);
//     return !formState.selectedCountry ? country.code.toLowerCase() : geoCountry ? geoCountry.toLowerCase() : 'om';
//   };
//   const [doPaymentRegister, { loading: PaymentRegisterLoading }] = useMutation(PAYMENT_REGISTER);
//   const [doRegister, { loading: registerLoading, error: registerError }] = useMutation(REGISTER);

//   if (formState.paymentFailedLoading) {
//     return (
//       <Box sx={{ /* your styles */ }}>
//         <Stack sx={{ /* your styles */ }}>
//           <GppBadIcon sx={{ fontSize: 96, color: 'error' }} />
//           <Typography variant="h5" color={"black"} sx={{ marginBottom: '20px', fontWeight: 'bold' }}>
//             Payment failed. Please try again.
//           </Typography>
//           <CircularProgress color="error" size={36} />
//         </Stack>
//       </Box>
//     );
//   }

//   if (PaymentRegisterLoading) {
//     return (
//       <Box sx={{ /* your styles */ }}>
//         <Stack sx={{ /* your styles */ }}>
//           <Typography variant="h5" color={"black"} sx={{ marginBottom: '20px', fontWeight: 'bold' }}>
//             Please Wait...
//           </Typography>
//           <CircularProgress color="warning" size={36} />
//         </Stack>
//       </Box>
//     );
//   }

//   return (
//     <>
//       <SearchModal
//         eventId={eventId}
//         role={role}
//         open={formState.openModal}
//         onClose={handleModalClose}
//       />

//       {featuresList.includes('DownloadBadge') && (
//         <Stack direction="row" justifyContent={"center"} spacing={2} alignItems="center" p={2}>
//           <Typography variant="body1" onClick={handleModalOpen} textAlign={"center"}>
//             If you have previously registered, <Typography variant="caption" onClick={handleModalOpen} sx={{ color: "blueviolet", fontSize: 18, cursor: "pointer" }}>click here </Typography>to retrieve your badge.
//           </Typography>
//         </Stack>
//       )}

//       <form noValidate onSubmit={handleSubmit(async (f) => {
//         // your form submission logic
//       })}>
//         <Grid container spacing={2}>
//           {fields.map((f) => (
//             <React.Fragment key={f.name}>
//               <Grid item xs={["mcq", "radio", "payment"].includes(f.type) ? 12 : f.xs} md={["mcq", "radio", "payment"].includes(f.type) ? 12 : f.md}>
//                 <Controller
//                   name={f.name}
//                   control={control}
//                   render={(rhf) => (
//                     <MyField
//                       rhf={rhf}
//                       f={f}
//                       setValue={setValue}
//                       findCountryCodeByDialCode={findCountryCodeByDialCode}
//                       selectedCountry={formState.selectedCountry}
//                       setSelectedCountry={(country) => setFormState(prev => ({ ...prev, selectedCountry: country }))}
//                       selectedMobileCode={formState.selectedMobileCode}
//                       setSelectedMobileCode={(code) => setFormState(prev => ({ ...prev, selectedMobileCode: code }))}
//                       parsedRegistration={formState.parsedRegistration}
//                       pmt={pmt}
//                     />
//                   )}
//                 />
//               </Grid>
//               {f.options?.some((op) => op === "$Other") && watch(f.name)?.includes("$Other") && (
//                 <Grid item xs={f.xs} md={f.md}>
//                   <Controller
//                     name={f.name + "_other"}
//                     control={control}
//                     rules={{
//                       required: f.required ? "Required" : false,
//                       maxLength: f.maxLength,
//                       minLength: f.minLength,
//                       // pattern: f.pattern
//                     }}
//                     render={(rhf) => (
//                       <MyField
//                         rhf={rhf}
//                         parsedRegistration={formState.parsedRegistration}
//                         f={{
//                           type: "text",
//                           name: "Enter value",
//                           placeholder: "Custom value",
//                           required: f.required,
//                           sx: {},
//                         }}
//                         selectedCountry={formState.selectedCountry}
//                         setSelectedCountry={(country) => setFormState(prev => ({ ...prev, selectedCountry: country }))}
//                         setValue={setValue}
//                         findCountryCodeByDialCode={findCountryCodeByDialCode}
//                         selectedMobileCode={formState.selectedMobileCode}
//                         setSelectedMobileCode={(code) => setFormState(prev => ({ ...prev, selectedMobileCode: code }))}
//                         pmt={pmt}
//                       />
//                     )}
//                   />
//                 </Grid>
//               )}
//             </React.Fragment>
//           ))}
//           <Grid item xs={12}>
//             <Divider />
//           </Grid>
//           {registerError && (
//             <Grid item xs={12}>
//               <Typography color="error">{registerError?.message}</Typography>
//             </Grid>
//           )}
//           {registerLoading && (
//             <Grid item xs={ 12}>
//               <LinearProgress />
//             </Grid>
//           )}
//           {pmt && pmt.amount === 0 ? null : paymentData?.length > 1 && featuresList.includes('PaymentEnable') && (
//             <Grid item xs={12}>
//               <Controller
//                 name="paymentMethod"
//                 control={control}
//                 defaultValue={paymentData[0]?.paymentMethod}
//                 rules={{ required: 'Please select a payment method' }}
//                 render={({ field }) => (
//                   <FormControl>
//                     <FormLabel id="demo-row-radio-buttons-group-label">Select Payment Option</FormLabel>
//                     <RadioGroup {...field}>
//                       <Stack direction="row" spacing={2}>
//                         {paymentData.map((item, i) => (
//                           <CustomRadioButton
//                             key={i}
//                             item={item}
//                             setSelectedPaymentOption={(option) => setFormState(prev => ({ ...prev, selectedPaymentOption: option }))}
//                           />
//                         ))}
//                       </Stack>
//                     </RadioGroup>
//                   </FormControl>
//                 )}
//               />
//             </Grid>
//           )}

//           <Grid item xs={12}>
//             <Stack direction="column" justifyContent={"center"} spacing={2} alignItems="center">
//               {featuresList.includes('Captcha') && !formState.captchaOff && (
//                 <Turnstile
//                   sitekey="0x4AAAAAAAXSYmh0wYoO56yA"
//                   onVerify={(token) => setFormState(prev => ({ ...prev, token }))}
//                 />
//               )}
//               {formState.emailError && <Typography color="error">{formState.emailError}</Typography>}
//               <LoadingButton
//                 loading={registerLoading}
//                 type="submit"
//                 size="large"
//                 variant="contained"
//                 sx={{ width: { xs: "100%", md: 220 } }}
//                 endIcon={<AppRegistrationIcon />}
//               >
//                 Submit
//               </LoadingButton>

//             </Stack>
//             <PaymentSummaryDialog
//               open={formState.open}
//               handleClose={handleClose}
//               price={formState.price}
//               selectedCurrency={formState.selectedCurrency}
//               clientId={formState.clientId}
//               secretKey={formState.secretKey}
//               publicKey={formState.publicKey}
//               handlePaymentSuccess={handlePaymentSuccess}
//               allData={formState.allData}
//               setErrorInPaymentInitialization={(error) => setFormState(prev => ({ ...prev, errorInPaymentInitialization: error }))}
//               selectedPaymentOption={formState.selectedPaymentOption}
//               base={formState.apiurl}
//             />
//           </Grid>
//         </Grid>
//       </form>
//     </>
//   );
// }

export function RegForm({
  fields,
  onSubmit,
  eventId,
  role,
  featuresList,
  registrationId,
  pmt,
}) {
  const [openModal, setOpenModal] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [clientId, setClientId] = React.useState(null);
  const [price, setPrice] = React.useState(null);
  const [selectedCurrency, setSelectedCurrency] = React.useState(null);
  const [allData, setAllData] = React.useState(null);
  const [apiurl, setApiurl] = React.useState(null);
  const [secretKey, setSecretKey] = React.useState(null);
  const [publicKey, setPublicKey] = React.useState(null);
  const [emailError, setEmailError] = React.useState("");
  const [selectedPaymentOption, setSelectedPaymentOption] = React.useState("");
  const [paymentFailedLoading, setPaymentFailedLoading] = React.useState(false);
  const [token, setToken] = React.useState(null);
  const [captchaOff, setCapthaoff] = React.useState(false);
  const [selectedCountry, setSelectedCountry] = React.useState(null);
  const [selectedMobileCode, setSelectedMobileCode] = React.useState(null);

  const [isDefaultCountrySelected, setIsDefaultCountrySelected] =
    React.useState(false);
  const [errorInPaymentInitialization, setErrorInPaymentInitialization] =
    React.useState(false);
  const [isPopupNotOpened, setIsPopupNotOpened] = React.useState(false);
  const parsedRegistration = registrationId && typeof (registrationId) === "string" ? JSON.parse(registrationId) : registrationId;

  const [showFamilyMembers, setShowFamilyMembers] = React.useState(false);
  const navigate = useNavigate();
  const { link } = useParams();
  const [email, setEmail] = React.useState("");
  const { checkEmail: isEmailTaken, loading: emailCheckLoading } =
    useCheckEmail(
      !featuresList?.includes("duplicateEmailAllowed") ? email : null,
      !featuresList?.includes("duplicateEmailAllowed") ? link : null
    );

  const [otpModalProps, setOtpModalProps] = React.useState({
    isOpen: false,
    mobile: null,
    eventId: null,
    onVerify: null,
  });
  const turnstile = useTurnstile();
  const { paymentData } = usePaymentDetail(eventId, role);

  React.useEffect(() => {
    if (link && link.includes("survey")) {
      navigate(`/survey/${link}`);
    }
  }, [link, navigate]);

  const {
    country: geoCountry,
    error: geoError,
    isLoading: geoIsLoading,
  } = useGeoLocation();

  // console.log(">>>>>", geoCountry && typeof geoCountry, geoCountry);

  React.useEffect(() => {
    if (
      !isDefaultCountrySelected &&
      geoCountry &&
      featuresList?.includes("GeoLocation")
    ) {
      const isdCode = countries.find((c) => c.code === geoCountry);
      setSelectedCountry(isdCode?.label);
      setIsDefaultCountrySelected(true);
      console.log(isdCode, ">>>><<<<");
      setSelectedMobileCode(isdCode?.dial_code);
    }
  }, [geoCountry, isDefaultCountrySelected]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const [
    doPaymentRegister,
    { loading: PaymentRegisterLoading, error: PaymentRegisterError },
  ] = useMutation(PAYMENT_REGISTER);

  const handlePaymentSuccess = async (details, actions) => {
    try {
      if (
        details?.payment_status === "COMPLETED" ||
        details?.payment_status === "success"
      ) {
        handleClose();
        const updatedAllData = { ...allData, payment_status: "success" };
        const reglink = pmt ? pmt.param : null;
        const mobile = allData?.mobile || allData?.phone || allData?.Mobile;
        const calling_code = allData?.calling_code || allData?.country_code || allData?.CallingCode;
        const updatedMobile = mobile ? `${calling_code.replace('+', '')}${mobile}` : null;
        const isOTPRequired = featuresList?.includes("OTP");

        if (isOTPRequired) {
          // Show OTP Modal and wait for verification
          const otpVerified = await new Promise((resolve) => {
            setOtpModalProps({
              isOpen: true,
              mobile: updatedMobile,
              eventId: eventId, // Pass event ID if available
              onVerify: (success) => {
                setOtpModalProps({ ...otpModalProps, isOpen: false });
                resolve(success);
              },
            });
          });

          if (!otpVerified) {
            console.log("OTP verification failed.");
            return; // Stop further execution if OTP verification fails
          }
        }



        const result = await doPaymentRegister({
          variables: {
            link: JSON.stringify({ link, pmtLink: reglink }),
            jsonData: JSON.stringify(updatedAllData),
            transaction: JSON.stringify(details),
          },
        }).then((res) => res.data?.paymentRegister);

        // console.log(result, ">>>>>>>>");
        if (featuresList.includes("Captcha") && result) {
          turnstile.reset();
          setToken(null);
          setCapthaoff(true);
        }
        setSelectedPaymentOption("");

        // console.log("hiiiiii");
        return onSubmit(result);
      }
      if (
        details?.payment_status === "FAILED" ||
        details?.payment_status === "failed"
      ) {
        handleClose();
        setPaymentFailedLoading(true);
      }
    } catch (error) {
      setEmailError(
        `Payment Status: ${details?.payment_status} <br /> Registration Status: ${error?.message} <br/> Please contact organizer to resolve this issue. Sorry for the in convinience`
      );
      console.error("Error during payment:", error);
    }
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const { control, handleSubmit, setValue, watch, reset } = useForm({
    defaultValues: fields.reduce(
      (a, r) => ({
        ...a,
        [r.name]:
          r.type === "checkbox"
            ? !!parseInt(r.defaultValue || "0")
            : r.type === "mcq"
              ? []
              : r.defaultValue || "",
      }),
      {}
    ),
    resolver: yupResolver(
      yup.object().shape({
        ...fields.reduce(
          (a, r) => ({ ...a, [r.name]: buildValidation(r) }),
          {}
        ),
        ...fields
          .filter((r) => r.options?.some((op) => op === "$Other"))
          ?.reduce(
            (a, r) => ({
              ...a,
              [r.name + "_other"]: yup
                .string()
                .min(r.minLength, "Too long")
                .max(r.maxLength, "Too long")
                // .matches(r.pattern, "Invalid pattern")
                .test({
                  message: "Required",
                  test: (a, p) =>
                    p.parent[r.name]?.includes("$Other") ? !!a : true,
                }),
            }),
            {}
          ),
      })
    ),
  });
  const handleClose = () => {
    setOpen(false);
    (errorInPaymentInitialization || isPopupNotOpened) && reset();
    errorInPaymentInitialization && setErrorInPaymentInitialization(false);
    isPopupNotOpened && setIsPopupNotOpened(false);
    errorInPaymentInitialization && setErrorInPaymentInitialization(false);
    isPopupNotOpened && setIsPopupNotOpened(false);
  };

  React.useEffect(() => {
    if (errorInPaymentInitialization) {
      handleClose();
      setEmailError("Something went wrong please Try Again Later!");
    }
    if (isPopupNotOpened) {
      handleClose();
      setEmailError(
        "Please allow pop-ups for this website to proceed with the payment. \n In the address bar, click the Pop-up blocked icon 🔒. then select always allow popups"
      );
    }
    // if (!selectedPaymentOption || !allData?.paymentMethod) {
    //   setEmailError("Please Select Payment Method")
    // }
  }, [errorInPaymentInitialization, isPopupNotOpened]);

  const [doRegister, { loading: registerLoading, error: registerError }] =
    useMutation(REGISTER);
  const findCountryCodeByDialCode = (dialCode) => {
    // console.log("dial_code", dialCode);
    const country = countries.find((c) => c.dial_code === dialCode);
    console.log(country, "country");
    return !selectedCountry ? country.label : "Oman";
  };
  const handlePaymentSubmit = async (e) => {
    try {
      const match = e.payment.match(/\d+/);
      // console.log("hbjvbjhvjhvjh",match);
      const amount = match ? parseFloat(match[0]) : 0;
      // const currency = match ? match[2] : "";
      let matchingPaymentMethodData;
      if (paymentData?.length > 1) {
        matchingPaymentMethodData = paymentData.find(
          (data) => data.paymentMethod === e.paymentMethod
        );
      } else {
        matchingPaymentMethodData = paymentData[0];
      }

      const { apiKey, apiurl, secretKey, publicKey, currency } =
        matchingPaymentMethodData || {};
      // console.log("publicKey", publicKey);
      setAllData(e);
      setPrice(amount);
      setSelectedCurrency(currency);
      setClientId(apiKey);
      setApiurl(apiurl);
      setSecretKey(secretKey);
      setPublicKey(publicKey);
      handleClickOpen();
    } catch (error) {
      console.error("Error during payment:", error);
    }
  };

  React.useEffect(() => {
    let timer;
    if (paymentFailedLoading) {
      timer = setTimeout(() => {
        setPaymentFailedLoading(false);
        window.location.reload();
      }, 7000); // 7 seconds
    }

    return () => clearTimeout(timer);
  }, [paymentFailedLoading]);

  if (paymentFailedLoading) {
    return (
      <Box
        sx={{
          width: "100%",
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "8px",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f0f0f0", // background color
            borderRadius: "8px", // border radius
            padding: "20px", // padding
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // shadow
          }}
        >
          <GppBadIcon sx={{ fontSize: 96, color: "error" }} color={"error"} />
          <Typography
            variant="h5"
            color={"black"}
            sx={{ marginBottom: "20px", fontWeight: "bold" }}
          >
            Payment failed. Please try again.
          </Typography>
          <CircularProgress color="error" size={36} />
        </Stack>
      </Box>
    );
  }

  if (PaymentRegisterLoading)
    return (
      <Box
        sx={{
          width: "100%",
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "#f0f0f0", // background color
          borderRadius: "8px", // border radius
          padding: "20px", // padding
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // shadow
        }}
      >
        <Stack
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#f0f0f0", // background color
            borderRadius: "8px", // border radius
            padding: "20px", // padding
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // shadow
          }}
        >
          {/* <CheckCircleIcon sx={{ fontSize: 96, color: 'GrayText' }} color={"secondary"} /> */}
          <Typography
            variant="h5"
            color={"black"}
            sx={{ marginBottom: "20px", fontWeight: "bold" }}
          >
            Please Wait...
          </Typography>
          <CircularProgress color="warning" size={36} />
        </Stack>
      </Box>
    );

  return (
    <>
      <SearchModal
        eventId={eventId}
        role={role}
        open={openModal}
        onClose={handleModalClose}
      />
      <OtpModal  {...otpModalProps} />
      {featuresList.includes("DownloadBadge") && (
        <Stack
          direction="row"
          justifyContent={"center"}
          spacing={2}
          alignItems="center"
          p={2}
        >
          <Typography variant="body1" textAlign={"center"}>
            إذا قمت بالتسجيل مسبقًا،{" "}
            <Typography
              variant="caption"
              onClick={handleModalOpen}
              sx={{ color: "blueviolet", fontSize: 18, cursor: "pointer" }}
            >
              اضغط هنا{" "}
            </Typography>
            لاسترجاع بطاقة الدخول.
            <br />
            If you have previously registered,{" "}
            <Typography
              variant="caption"
              onClick={handleModalOpen}
              sx={{ color: "blueviolet", fontSize: 18, cursor: "pointer" }}
            >
              click here{" "}
            </Typography>
            to retrieve your badge.
          </Typography>
        </Stack>
      )}

      <form
        noValidate
        onSubmit={handleSubmit(async (f) => {
          if (
            isEmailTaken === "Email already exists" &&
            !featuresList.includes("duplicateEmailAllowed")
          ) {
            // Prevent form submission
            return;
          }
          const v = {};
          for (const r of fields) {
            if (r.options?.some((op) => op === "$Other")) {
              if (["select", "radio", "payment"].includes(r.type)) {
                if (f[r.name] === "$Other") {
                  v[r.name] = "Other: " + (f[r.name + "_other"] || "");
                  continue;
                }
              } else if (typeof f[r.name] === "object") {
                if (f[r.name]?.includes("$Other")) {
                  v[r.name] = [
                    ...(f[r.name]?.filter((o) => o !== "$Other") || []),
                    "Other: " + (f[r.name + "_other"] || ""),
                  ]
                    .filter((a) => !!a?.trim())
                    .join(", ");
                  continue;
                } else {
                  v[r.name] = (f[r.name] || [])
                    .filter((a) => !!a?.trim())
                    .join(", ");
                  continue;
                }
              }
            } else if (typeof f[r.name] === "object") {
              v[r.name] = f[r.name].join(", ");
            } else {
              v[r.name] = f[r.name];
            }
          }

          if (featuresList.includes("Captcha") && !captchaOff) {
            if (!token) {
              setEmailError("Captcha Required");
              return;
            }
            setEmailError(null);
          } else {
            setEmailError(null);
          }

          let wholeData = {};
          const isPmtTrue = pmt === null ? true : pmt?.amount > 0;
          try {
            // If payment is enabled and `pmt.amount > 0`, process payment
            if (featuresList.includes("PaymentEnable") && isPmtTrue) {
              if (paymentData?.length > 1) {
                wholeData = { paymentMethod: f.paymentMethod, ...v };
                // handlePaymentSubmit(wholeData);
                try {
                  const match = wholeData.payment.match(/\d+/);
                  const amount = match ? parseFloat(match[0]) : 0;
                  // const currency = match ? match[2] : "";
                  let matchingPaymentMethodData;
                  if (paymentData?.length > 1) {
                    matchingPaymentMethodData = paymentData.find(
                      (data) => data.paymentMethod === wholeData.paymentMethod
                    );
                  } else {
                    matchingPaymentMethodData = paymentData[0];
                  }

                  const { apiKey, apiurl, secretKey, publicKey, currency } =
                    matchingPaymentMethodData || {};
                  // consolwholeData.log("publicKey", publicKey);
                  setAllData(wholeData);
                  setPrice(amount);
                  setSelectedCurrency(currency);
                  setClientId(apiKey);
                  setApiurl(apiurl);
                  setSecretKey(secretKey);
                  setPublicKey(publicKey);
                  handleClickOpen();
                } catch (error) {
                  console.error("Error during payment:", error);
                }
              } else if (paymentData?.length === 1) {
                wholeData = {
                  paymentMethod: paymentData[0]?.paymentMethod,
                  ...v,
                };
                try {
                  const match = wholeData.payment.match(/\d+/);
                  const amount = match ? parseFloat(match[0]) : 0;
                  // const currency = match ? match[2] : "";
                  let matchingPaymentMethodData;
                  if (paymentData?.length > 1) {
                    matchingPaymentMethodData = paymentData.find(
                      (data) => data.paymentMethod === wholeData.paymentMethod
                    );
                  } else {
                    matchingPaymentMethodData = paymentData[0];
                  }

                  const { apiKey, apiurl, secretKey, publicKey, currency } =
                    matchingPaymentMethodData || {};
                  // consolwholeData.log("publicKey", publicKey);
                  setAllData(wholeData);
                  setPrice(amount);
                  setSelectedCurrency(currency);
                  setClientId(apiKey);
                  setApiurl(apiurl);
                  setSecretKey(secretKey);
                  setPublicKey(publicKey);
                  handleClickOpen();
                } catch (error) {
                  console.error("Error during payment:", error);
                }
              }
            } else {
              // If `pmt.amount === 0`, skip payment and proceed to form submission
              const reglink = pmt ? pmt.param : null;
              const mobile = v?.mobile || v?.phone || v?.Mobile;
              const calling_code = v?.calling_code || v?.country_code || v?.CallingCode;
              const updatedMobile = mobile ? `${calling_code.replace('+', '')}${mobile}` : null;
              const isOTPRequired = featuresList?.includes("OTP");
              console.log(v)
              if (isOTPRequired) {
                // Show OTP Modal and wait for verification
                const otpVerified = await new Promise((resolve) => {
                  setOtpModalProps({
                    isOpen: true,
                    mobile: updatedMobile,
                    eventId: eventId, // Pass event ID if available
                    onVerify: (success) => {
                      setOtpModalProps({ ...otpModalProps, isOpen: false });
                      resolve(success);
                    },
                  });
                });

                if (!otpVerified) {
                  console.log("OTP verification failed.");
                  return; // Stop further execution if OTP verification fails
                }
              }
              const result = await doRegister({
                variables: {
                  link: JSON.stringify({ link, pmtLink: reglink }),
                  jsonData: JSON.stringify(v),
                },
              })
                .then((res) => res.data?.register)
                .catch((e) => false);

              if (featuresList.includes("Captcha") && result) {
                turnstile.reset();
                setToken(null);
                setCapthaoff(true);
              }
              return onSubmit(result);
            }
          } catch (e) {
            console.log(e);
          }
        })}
      >
        <Grid container spacing={2}>
          {fields
            ?.map((f) => ({
              ...f,
              sx: f.sx ? JSON.parse(f.sx) : {},
            }))
            ?.map((f) => (
              <React.Fragment key={f.name}>
                <Grid
                  item
                  xs={["mcq", "radio", "payment"].includes(f.type) ? 12 : f.xs}
                  md={["mcq", "radio", "payment"].includes(f.type) ? 12 : f.md}
                >
                  <Controller
                    name={f.name}
                    control={control}
                    render={(rhf) => (
                      <MyField
                        rhf={rhf}
                        f={f}
                        setValue={setValue}
                        findCountryCodeByDialCode={findCountryCodeByDialCode}
                        selectedCountry={selectedCountry}
                        setSelectedCountry={setSelectedCountry}
                        selectedMobileCode={selectedMobileCode}
                        setSelectedMobileCode={setSelectedMobileCode}
                        parsedRegistration={parsedRegistration}
                        pmt={pmt}
                        setEmail={setEmail}
                        isEmailTaken={isEmailTaken}
                        emailCheckLoading={emailCheckLoading}
                        showFamilyMembers={showFamilyMembers}
                      />
                    )}
                  />
                </Grid>
                {f.options?.some((op) => op === "$Other") &&
                  watch(f.name)?.includes("$Other") && (
                    <Grid item xs={f.xs} md={f.md}>
                      <Controller
                        name={f.name + "_other"}
                        control={control}
                        rules={{
                          required: f.required ? "Required" : false,
                          maxLength: f.maxLength,
                          minLength: f.minLength,
                          // pattern: f.pattern
                        }}
                        render={(rhf) => (
                          <MyField
                            rhf={rhf}
                            parsedRegistration={parsedRegistration}
                            f={{
                              type: "text",
                              name: "Enter value",
                              placeholder: "Custom value",
                              required: f.required,
                              sx: {},
                            }}
                            selectedCountry={selectedCountry}
                            setSelectedCountry={setSelectedCountry}
                            setValue={setValue}
                            findCountryCodeByDialCode={
                              findCountryCodeByDialCode
                            }
                            selectedMobileCode={selectedMobileCode}
                            setSelectedMobileCode={setSelectedMobileCode}
                            pmt={pmt}
                            setEmail={setEmail}
                            isEmailTaken={isEmailTaken}
                            emailCheckLoading={emailCheckLoading}
                            showFamilyMembers={showFamilyMembers}
                          />
                        )}
                      />
                    </Grid>
                  )}
              </React.Fragment>
            ))}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {registerError && (
            <Grid item xs={12}>
              <Typography color="error">{registerError?.message}</Typography>
            </Grid>
          )}
          {registerLoading && (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          )}
          {pmt && pmt.amount === 0
            ? null
            : paymentData?.length > 1 &&
            featuresList.includes("PaymentEnable") && (
              <Grid item xs={12}>
                <Controller
                  name="paymentMethod"
                  control={control}
                  defaultValue={paymentData[0]?.paymentMethod}
                  rules={{ required: "Please select a payment method" }}
                  render={({ field }) => (
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Select Payment Option
                      </FormLabel>
                      <RadioGroup {...field}>
                        <Stack direction="row" spacing={2}>
                          {paymentData.map((item, i) => (
                            <CustomRadioButton
                              key={i}
                              item={item}
                              setSelectedPaymentOption={
                                setSelectedPaymentOption
                              }
                            />
                          ))}
                        </Stack>
                      </RadioGroup>
                    </FormControl>
                  )}
                />
              </Grid>
            )}

          <Grid item xs={12}>
            <Stack
              direction="column"
              justifyContent={"center"}
              spacing={2}
              alignItems="center"
            >
              {featuresList.includes("GroupBookingEnable") && ( // Check if GroupBookingEnable feature is enabled
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showFamilyMembers}
                      onChange={(e) => setShowFamilyMembers(e.target.checked)} // Update state on checkbox change
                    />
                  }
                  label="Add Family Members"
                />
              )}
              {featuresList.includes("Captcha") && !captchaOff && (
                <Turnstile
                  // sitekey="1x00000000000000000000AA"
                  sitekey="0x4AAAAAAAXSYmh0wYoO56yA"
                  onVerify={(token) => {
                    setToken(token);

                    // console.log("Token>>>>>>>>>",token);
                    // fetch("/login", {
                    //   method: "POST",
                    //   body: JSON.stringify({ token }),
                    // }).then((response) => {
                    //   if (!response.ok) turnstile.reset();
                    // });
                  }}
                />
              )}
              {emailError && (
                <Typography color="error">
                  <div dangerouslySetInnerHTML={{ __html: emailError }}></div>
                </Typography>
              )}
              <LoadingButton
                loading={registerLoading}
                type="submit"
                size="large"
                variant="contained"
                sx={{
                  width: { xs: "100%", md: 220 },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: 1, // Adds space between the text and the icon
                  flexDirection: "row-reverse", // Arabic text first, English and icon follow
                }}
              >
                <Typography component="span" sx={{ direction: "rtl" }}>
                  إرسال
                </Typography>
                <AppRegistrationIcon />
                <Typography component="span" sx={{ direction: "ltr" }}>
                  Submit
                </Typography>
              </LoadingButton>


            </Stack>

            <PaymentSummaryDialog
              open={open}
              handleClose={handleClose}
              price={price}
              selectedCurrency={selectedCurrency}
              clientId={clientId}
              secretKey={secretKey}
              publicKey={publicKey}
              handlePaymentSuccess={handlePaymentSuccess}
              allData={allData}
              setErrorInPaymentInitialization={setErrorInPaymentInitialization}
              selectedPaymentOption={selectedPaymentOption}
              base={apiurl}
            />
          </Grid>
        </Grid>
      </form>
    </>
  );
}

function PaymentSummaryDialog({
  open,
  handleClose,
  price,
  selectedCurrency,
  clientId,
  handlePaymentSuccess,
  secretKey,
  publicKey,
  selectedPaymentOption,
  allData,
  base,
  setErrorInPaymentInitialization,
}) {
  const screenSize =
    selectedPaymentOption === "paypal" || allData?.paymentMethod === "paypal"
      ? "fullWidth"
      : "fullScreen";
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      minWidth="sm"
      fullScreen={screenSize === "fullScreen"} // Use fullScreen prop if screenSize is "fullScreen"
      fullWidth={screenSize === "fullWidth"} // Use fullWidth prop if screenSize is "fullWidth"
      sx={{ backgroundColor: "rgba(0, 0, 0, 0.4)" }}
      BackdropProps={{
        // Disable backdrop click to close
        style: {
          pointerEvents: "none",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
        },
      }}
      PaperProps={{
        // Style the paper
        style: {
          maxWidth: "100%",
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.3)",
        },
      }}
    >
      {/* <DialogTitle>Payment Page</DialogTitle> */}

      <DialogContent sx={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
        {/* <Box p={1} /> */}
        {!clientId || !secretKey ? (
          <>
            {/* {console.log(clientId,secretKey)} */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress color="secondary" size={28} />
            </Box>
          </>
        ) : selectedPaymentOption === "paypal" ||
          allData?.paymentMethod === "paypal" ? (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Detail
                    </TableCell>
                    <TableCell align="right">{allData?.payment}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Payable Amount
                    </TableCell>
                    <TableCell align="right">
                      {price} {selectedCurrency}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <PaypalBtns
              currency={selectedCurrency}
              amount={price}
              onPaymentSuccess={handlePaymentSuccess}
              buyerEmail={allData?.Email || allData?.email}
              clientId={clientId}
              description={allData?.payment}
              base={base}
            />
          </>
        ) : selectedPaymentOption === "paymob" ||
          allData?.paymentMethod === "paymob" ? (
          <PaymobBtns
            currency={selectedCurrency}
            amount={price}
            onPaymentSuccess={handlePaymentSuccess}
            buyerEmail={allData?.Email || allData?.email}
            clientId={clientId}
            description={allData?.payment}
            base={base}
            setErrorInPaymentInitialization={setErrorInPaymentInitialization}
            setIsPopupNotOpened={"setIsPopupNotOpened"}
            secretKey={secretKey}
            publicKey={publicKey}
            allData={allData}
          />
        ) : null}
      </DialogContent>
      {selectedPaymentOption === "paypal" ||
        allData?.paymentMethod === "paypal" ? (
        <DialogActions>
          <Button color="error" variant="contained" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      ) : (
        ""
      )}
    </Dialog>
  );
}

function MyField({
  rhf,
  f,
  setValue,
  findCountryCodeByDialCode,
  selectedCountry,
  setSelectedCountry,
  selectedMobileCode,
  setSelectedMobileCode,
  parsedRegistration,
  pmt,
  setEmail,
  isEmailTaken,
  emailCheckLoading,
  showFamilyMembers,
}) {
  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    rhf.field.onChange(newEmail);
    setEmail(newEmail);
  };
  const errorMessage = rhf.fieldState.error?.message;
  if (f.name === "user_id" && parsedRegistration && parsedRegistration.id)
    setValue("user_id", parsedRegistration?.id);
  if (f.name === "Email" && parsedRegistration && parsedRegistration.Email)
    setValue("Email", parsedRegistration?.Email);
  // console.log("rhf", rhf,"f", f,"setValue", setValue,"findCountryCodeByDialCode", findCountryCodeByDialCode );
  if (f.type === "mcq") {
    return (
      <FormControl
        fullWidth
        required={f.required}
        sx={showFamilyMembers ? {} : f.sx || {}}
        error={!!rhf.fieldState.error}
      >
        <FormLabel>{f.label || f.name}</FormLabel>
        <FormGroup>
          <Grid container spacing={1}>
            {f.options
              ?.map((op) => op.trim())
              ?.map((op, i) => (
                <Grid item xs={f.xs || 12} md={f.md || 6} key={i}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name={op}
                        checked={rhf.field.value?.includes(op)}
                        onChange={(e) => {
                          const arr = rhf.field.value || [];
                          if (e.target.checked) {
                            arr.push(op);
                          } else if (arr.includes(op)) {
                            arr.splice(arr.indexOf(op), 1);
                          }
                          // console.log("arr", arr);
                          rhf.field.onChange(arr);
                        }}
                      />
                    }
                    label={
                      op === "$Other" ? (
                        "Other:"
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: op,
                          }}
                        />
                      )
                    }
                  />
                </Grid>
              ))}
          </Grid>
        </FormGroup>
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    );
  } else if (f.type === "radio") {
    return (
      <FormControl
        fullWidth
        required={f.required}
        sx={showFamilyMembers ? {} : f.sx || {}}
        error={!!rhf.fieldState.error}
      >
        <FormLabel>{f.label || f.name}</FormLabel>
        <RadioGroup
          name={f.name}
          onChange={rhf.field.onChange}
          onBlur={rhf.field.onBlur}
          value={rhf.field.value}
        >
          <Grid container spacing={1}>
            {f.options?.map((op, i) => (
              <Grid item xs={f.xs || 12} md={f.md || 6} key={i}>
                <FormControlLabel
                  control={<Radio />}
                  label={op === "$Other" ? "Other:" : op}
                  value={op}
                />
              </Grid>
            ))}
          </Grid>
        </RadioGroup>
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    );
  } else if (f.type === "html") {
    return (
      <Typography
        variant="body1"
        sx={showFamilyMembers ? {} : f.sx || {}}
        dangerouslySetInnerHTML={{ __html: f.defaultValue }}
      />
    );
  } else if (f.type === "payment") {
    if (pmt && pmt.amount === 0) {
      return;
    }
    if (pmt && pmt.amount > 0) {
      return (
        <FormControl
          fullWidth
          required={f.required}
          sx={showFamilyMembers ? {} : f.sx || {}}
          error={!!rhf.fieldState.error}
        >
          <FormLabel>{f.label || f.name}</FormLabel>
          <RadioGroup
            name={f.name}
            onChange={rhf.field.onChange}
            onBlur={rhf.field.onBlur}
            value={rhf.field.value}
          >
            <Grid container spacing={1}>
              {/* {f.options?.map((op, i) => ( */}
              <Grid item xs={f.xs || 12} md={f.md || 6}>
                <FormControlLabel
                  control={<Radio />}
                  label={"Amount"}
                  value={pmt.amount}
                />
              </Grid>
              {/* ))} */}
            </Grid>
          </RadioGroup>
          {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
        </FormControl>
      );
    } else {
      return (
        <FormControl
          fullWidth
          required={f.required}
          sx={showFamilyMembers ? {} : f.sx || {}}
          error={!!rhf.fieldState.error}
        >
          <FormLabel>{f.label || f.name}</FormLabel>
          <RadioGroup
            name={f.name}
            onChange={rhf.field.onChange}
            onBlur={rhf.field.onBlur}
            value={rhf.field.value}
          >
            <Grid container spacing={1}>
              {f.options?.map((op, i) => (
                <Grid item xs={f.xs || 12} md={f.md || 6} key={i}>
                  <FormControlLabel
                    control={<Radio />}
                    label={op === "$Other" ? "Other:" : op}
                    value={op}
                  />
                </Grid>
              ))}
            </Grid>
          </RadioGroup>
          {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
        </FormControl>
      );
    }
  } else if (f.type === "checkbox") {
    return (
      <FormControl
        required={f.required}
        fullWidth
        sx={showFamilyMembers ? {} : f.sx || {}}
        error={!!rhf.fieldState.error}
      >
        <FormControlLabel
          onChange={rhf.field.onChange}
          onBlur={rhf.field.onBlur}
          inputRef={rhf.field.ref}
          control={<Checkbox />}
          label={f.label || f.name}
          name={rhf.field.name}
          checked={rhf.field.value}
        />
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    );
  } else if (f.type === "select") {
    console.log(f.sx, ">>>>", f.sx?.display === "none");
    // React.useEffect(() => {
    if (f.sx?.display === "none" && !showFamilyMembers) {
      setValue(rhf.field.name, "");
    }
    // }, [showFamilyMembers]);
    return (
      <TextField
        name={rhf.field.name}
        value={rhf.field.value}
        onChange={rhf.field.onChange}
        onBlur={rhf.field.onBlur}
        inputRef={rhf.field.ref}
        error={!!rhf.fieldState.error}
        helperText={errorMessage}
        label={f.label || f.name}
        required={f.required}
        sx={showFamilyMembers ? {} : f.sx || {}}
        fullWidth
        select
      >
        <MenuItem value="">None</MenuItem>
        {f.options.map((op) => (
          <MenuItem key={op} value={op}>
            {op === "$Other" ? "Other:" : op}
          </MenuItem>
        ))}
      </TextField>
    );
  } else if (f.type === "country") {
    // console.log(rhf.field.value,"????????");
    // const [CountryCode, setCountryCode] = React.useState(null);
    // const code = findCountryCodeByDialCode(rhf.field.value)
    // const countryCode = code && code.toUpperCase()

    const countryIsd = countries.find((c) => c.label === selectedCountry);
    // console.log(">>>>>>>>>",selectedCountry, countryIsd,">>>>>>>>");
    return (
      <Autocomplete
        options={countries}
        autoHighlight
        value={
          !rhf.field.value
            ? countries.find((c) => c.label === countryIsd?.label)
            : !selectedCountry
              ? countries.find((c) => c.label === rhf.field.value)
              : countries.find(
                (c) =>
                  c.label === countryIsd?.label || c.label === rhf.field.value
              )
        }
        onChange={(e, v) => {
          rhf.field.onChange(v?.label || "");
          setValue("calling_code", selectedMobileCode || v?.dial_code || "");
          setSelectedCountry(v?.label);
          setSelectedMobileCode(v?.dial_code || "");
        }}
        onBlur={rhf.field.onBlur}
        getOptionLabel={(o) => o?.label || ""}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img
              loading="lazy"
              width="20"
              srcSet={`https://flagcdn.com/w40/${countries
                .find((c) => c.label === option?.label)
                .code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${countries
                .find((c) => c.label === option?.label)
                .code.toLowerCase()}.png`}
              alt=""
            />
            {option?.label} ({option?.code}) {option?.dial_code}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
            name={rhf.field.name}
            inputRef={rhf.field.ref}
            error={!!rhf.fieldState.error}
            helperText={errorMessage}
            label={f.label || f.name}
            placeholder={f.placeholder}
            required={f.required}
            sx={showFamilyMembers ? {} : f.sx || {}}
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${!selectedCountry
                      ? "om"
                      : countryIsd?.code?.toLowerCase() || "om"
                      }.png`}
                    alt="country_flag"
                  />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    );
  } else if (f.type === "mobile_code" || f.type === "calling_code") {
    const countryName = findCountryCodeByDialCode(rhf.field.value);
    const countryIsd = selectedMobileCode
      ? countries.find((c) => c.dial_code === selectedMobileCode)
      : countries.find((c) => c.label === countryName);
    // console.log(
    //   ">>>>>>>>> selectedMobileCode",
    //   selectedMobileCode,
    //   countryIsd,
    //   ">>>>>>>>",
    //   rhf.field.value,
    //   findCountryCodeByDialCode(rhf.field.value)
    // );

    // selectedMobileCode = countries.find((c) => c.label === selectedCountry);
    console.log(selectedMobileCode, "selectedMobileCode");

    return (
      <Autocomplete
        options={countries}
        autoHighlight
        value={
          !rhf.field.value
            ? countries.find(
              (c) =>
                c.dial_code === countryIsd.dial_code || c.dial_code === "+968"
            )
            : !selectedMobileCode
              ? countries.find((c) => c.dial_code === rhf.field.value)
              : countries.find((c) => c.dial_code === countryIsd.dial_code)
        }
        onChange={(e, v) => {
          rhf.field.onChange(v?.dial_code || "");
          setSelectedMobileCode(v?.dial_code || "");
        }}
        onBlur={rhf.field.onBlur}
        getOptionLabel={(o) => o?.dial_code || ""}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <img
              loading="lazy"
              width="30"
              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
              alt=""
            />
            {option.dial_code}
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
            name={rhf.field.name}
            inputRef={rhf.field.ref}
            error={!!rhf.fieldState.error}
            helperText={errorMessage}
            label={f.label || f.name}
            placeholder={f.placeholder}
            required={f.required}
            sx={showFamilyMembers ? {} : f.sx || {}}
            fullWidth
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    loading="lazy"
                    width="20"
                    src={`https://flagcdn.com/w20/${countryIsd?.code?.toLocaleLowerCase()}.png`}
                    alt="country_flag"
                  />
                </InputAdornment>
              ),
            }}
          />
        )}
      />
    );
  } else if (f.type === "email") {
    return (
      <TextField
        type={f.type === "email"}
        InputProps={{
          autoComplete:
            f.autoComplete || f.type === "confirm_email"
              ? "new-password"
              : "on",
          readOnly: f.readonly,
        }}
        name={rhf.field.name}
        value={rhf.field.value}
        onChange={handleEmailChange}
        onBlur={rhf.field.onBlur}
        inputRef={rhf.field.ref}
        error={!!rhf.fieldState.error || isEmailTaken}
        helperText={
          emailCheckLoading
            ? "Checking email..."
            : isEmailTaken
              ? "This email is already registered"
              : errorMessage
        }
        label={f.label || f.name}
        placeholder={f.placeholder}
        required={f.required}
        // readonly={f.readonly}
        sx={showFamilyMembers ? {} : f.sx || {}}
        fullWidth
      />
    );
  } else {
    if (f.sx?.display === "none" && !showFamilyMembers) {
      setValue(rhf.field.name, "");
    }

    return (
      <TextField
        type={f.type === "number" ? "number" : "text"}
        InputProps={{
          autoComplete:
            f.autoComplete || f.type === "confirm_email"
              ? "new-password"
              : "on",
          readOnly: f.readonly,
        }}
        name={rhf.field.name}
        value={rhf.field.value}
        onChange={rhf.field.onChange}
        onBlur={rhf.field.onBlur}
        inputRef={rhf.field.ref}
        error={!!rhf.fieldState.error}
        helperText={errorMessage}
        label={f.label || f.name}
        placeholder={f.placeholder}
        required={f.required}
        // readonly={f.readonly}
        sx={showFamilyMembers ? {} : f.sx || {}}
        fullWidth
      />
    );
  }
}

const buildValidation = (f) => {
  let v = yup;
  if (f.type === "checkbox") {
    v = v.boolean();
  } else if (f.type === "select") {
    v = v.string();
  } else if (f.type === "mcq") {
    if (f.required) {
      return v.mixed().test({
        message: "Required",
        test: (v) => {
          return v?.length > 0;
        },
      });
    } else {
      return v.array().of(yup.string());
    }
  } else if (f.type === "radio" || f.type === "payment") {
    v = v.string();
  } else if (f.type === "number") {
    v = v.number();
  } else if (f.type === "email") {
    v = v.string().trim().email();
  } else if (f.type === "confirm_email") {
    v = v
      .string()
      .trim()
      .test({
        message: "Does not match",
        test: (val, p) => {
          if ("Email Address" in p.parent) {
            return val === p.parent["Email Address"];
          }
          return val === p.parent["Email"];
        },
      });
  } else {
    v = v.string().trim();
  }

  if (f.required) {
    v = v.required("Required");
  }

  if (
    f.maxLength &&
    !["checkbox", "radio", "payment", "select", "mcq"].includes(f.type)
  ) {
    v = v.max(
      f.maxLength,
      f.type === "number"
        ? `Max value: ${f.maxLength}`
        : `Max ${f.maxLength} characters allowed`
    );
  }
  if (
    f.minLength &&
    !["checkbox", "radio", "payment", "select", "mcq"].includes(f.type)
  ) {
    v = v.min(
      f.minLength,
      f.type === "number"
        ? `Min value: ${f.minLength}`
        : `Min ${f.minLength} characters required`
    );
  }
  if (f.pattern) {
    v = v.matches(f.pattern, "Invalid pattern!");
  }
  return v;
};

const REGISTER = gql`
  mutation ($link: String!, $jsonData: String!) {
    register(link: $link, jsonData: $jsonData)
  }
`;
const PAYMENT_REGISTER = gql`
  mutation ($link: String!, $jsonData: String!, $transaction: String!) {
    paymentRegister(link: $link, jsonData: $jsonData, transaction: $transaction)
  }
`;

const GET_REG_STRUCTURE = gql`
  query ($link: String!) {
    reg(link: $link) {
      eventId
      eventName
      role
      formHeading
      shareContent
      fields {
        name
        type
        label
        sx
        xs
        md
        placeholder
        options
        defaultValue
        minLength
        maxLength
        pattern
        required
        readonly
      }
    }
  }
`;
