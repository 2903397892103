import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { IMAGES_API_URL } from "./constants";

export default function SearchBadge() {
  const { eventId } = useParams(); // Get eventId from URL params
  const { control, handleSubmit, watch, setError, clearErrors } = useForm();
  const emailValue = watch("email");
  const mobileNumberValue = watch("mobileNumber");

  const [searchId, setSearchId] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const [role, setRole] = useState();

  const [searchBadgeFromAllRole, { loading, error }] = useMutation(gql`
    mutation searchBadgefromAllRole($eventId: String!, $searchData: String!) {
      searchBadgefromAllRole(eventId: $eventId, searchData: $searchData)
    }
  `);

  const handleSearch = async (variables) => {
    if (!variables.email && !variables.mobileNumber) {
      setError("formError", { message: "Please fill in at least one field." });
      return;
    }

    try {
      const searchData = variables.email || variables.mobileNumber;
      const response = await searchBadgeFromAllRole({
        variables: {
          searchData,
          eventId,
        },
      });

      // Parse the response data
      const { id, role } = JSON.parse(response.data.searchBadgefromAllRole);
      setSearchId(id); // Set searchId from parsed response
      setRole(role); // Set role from parsed response
      setShowButton(!!id); // Show button if id exists
    } catch (err) {
      console.error("Failed to search for badge.", err);
      setShowButton(false);
    }
  };

  const downloadUrl = `https://api-reg.eventegration.com/graphql/badge/${eventId}/${role}/${searchId}`;

  const handleDownload = () => {
    window.open(downloadUrl);
    setShowButton(null);
  };

  if (!eventId) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          bgcolor: "#f5f5f5",
          p: 3,
        }}
      >
        <Card
          sx={{
            maxWidth: 400,
            p: 3,
            borderRadius: 3,
            boxShadow: 3,
            textAlign: "center",
          }}
        >
          <Typography
            variant="h5"
            color="error"
            gutterBottom
            sx={{ fontWeight: "bold" }}
          >
            Invalid Link
          </Typography>
          <Typography variant="body1" color="textSecondary">
            The link you followed is incorrect or missing the required event
            information. Please check and try again.
          </Typography>
        </Card>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        bgcolor: "#f5f5f5",
        p: 3,
      }}
    >
      <Card
        sx={{
          maxWidth: 400,
          p: 3,
          borderRadius: 3,
          boxShadow: 3,
        }}
      >
        {!showButton ? (
          <CardContent>
            {eventId && (
              <img
                src={`${IMAGES_API_URL}logo/${eventId}.png`}
                width="100%"
                height={"100px"}
                style={{ objectFit: "contain" }}
                alt="Logo"
                onError={(e) => {
                  e.target.style.display = "none";
                }}
              />
            )}
            <Typography
              variant="h5"
              textAlign="center"
              gutterBottom
              sx={{ fontWeight: "bold", color: "#2a9d8f", mt: 2 }}
            >
              Search Your Badge
            </Typography>
            <Typography variant="subtitle1" textAlign="center" gutterBottom>
              Enter your registered email or mobile number to search.
            </Typography>
            <form
              onSubmit={handleSubmit((data) => {
                clearErrors("formError");
                handleSearch(data);
              })}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Email"
                        type="email"
                        fullWidth
                        sx={{ bgcolor: "#fff" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider>
                    <Chip label="OR" />
                  </Divider>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="mobileNumber"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Mobile Number"
                        type="number"
                        fullWidth
                        sx={{ bgcolor: "#fff" }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{
                      bgcolor: "#2a9d8f",
                      "&:hover": { bgcolor: "#21867a" },
                    }}
                  >
                    Search &nbsp;{" "}
                    {loading && <CircularProgress color="inherit" size={20} />}
                  </Button>
                </Grid>
                {error && (
                  <Stack
                    direction="row"
                    justifyContent="center"
                    sx={{ width: "100%" }}
                  >
                    <Typography textAlign="center" color="error" sx={{ mt: 2 }}>
                      {error.message}
                    </Typography>
                  </Stack>
                )}
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="error"
                    textAlign="center"
                    sx={{ mt: 2 }}
                  >
                    {control._formState.errors?.formError?.message}
                  </Typography>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        ) : (
          <CardContent>
            <Stack direction="column" justifyContent="center" spacing={3}>
              <Typography
                variant="h5"
                textAlign="center"
                gutterBottom
                sx={{ fontWeight: "bold", color: "#2a9d8f" }}
              >
                Download Your Badge
              </Typography>
              <Button
                onClick={handleDownload}
                variant="contained"
                color="success"
                fullWidth
              >
                Download Badge
              </Button>
            </Stack>
          </CardContent>
        )}
      </Card>
    </Box>
  );
}
