import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button } from '@mui/material';
import axios from "axios";

const OtpModal = ({ isOpen, onRequestClose, eventId, mobile, onVerify }) => {
    const [otp, setOtp] = useState('');
    const [newOtp, setNewOtp] = useState('');
    const [resendTimer, setResendTimer] = useState(20);
    const [resendCount, setResendCount] = useState(0);
    const [isResendDisabled, setIsResendDisabled] = useState(true);
    const [error, setError] = useState(null);
    const [otpSended, setOtpSended] = useState("");
  
    useEffect(() => {
      let timer;
      if (isResendDisabled && resendTimer > 0) {
        timer = setInterval(() => {
          setResendTimer((prev) => prev - 1);
        }, 1000);
      } else if (resendTimer === 0) {
        setIsResendDisabled(false);
      }
      return () => clearInterval(timer);
    }, [isResendDisabled, resendTimer]);
  
    useEffect(() => {
      if (eventId && mobile) {
        const generatedOtp = Math.floor(10000 + Math.random() * 90000); // Generate OTP
        setOtp(generatedOtp);
  
        // Send OTP via SMS
        axios
          .get(`http://localhost:4005/graphql/send-sms`, {
            params: {
              mobilenumber: mobile,
              message: `Your verification code is ${generatedOtp}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
                setOtpSended(`OTP sent successfully to ${mobile}.`);
              }
          })
          .catch((error) => {
            console.error("Error sending OTP:", error);
          });
      }
    }, [eventId, mobile]);
  
    const handleChange = (e) => {

      setNewOtp(e.target.value);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (!newOtp || newOtp.length !== 5) {
        setError("Please enter a valid 5-digit OTP");
        return;
      }
  
      if (parseInt(newOtp, 10) !== otp) {
        setError("Invalid OTP. Please try again.");
        return;
      }
  
      onVerify(true); // Notify parent component of success
      onRequestClose();
    };
  
    const handleResend = () => {
      if (resendCount < 3) {
        setResendCount((prev) => prev + 1);
        setResendTimer(20);
        setIsResendDisabled(true);
        const generatedOtp = Math.floor(10000 + Math.random() * 90000);
        setOtp(generatedOtp);
  
        // Resend OTP via SMS
        axios
          .get(`http://localhost:4005/graphql/send-sms`, {
            params: {
              mobilenumber: mobile,
              message: `Your verification code is ${generatedOtp}`,
            },
          })
          .then((response) => {
            if (response.status === 200) {
                setOtpSended(`OTP sent again successfully to ${mobile}.`);
                
              }
          })
          .catch((error) => {
            console.error("Error resending OTP:", error);
          });
      }
    };
  
    return (
      <Modal
        open={isOpen}
        onClose={onRequestClose}
        aria-labelledby="otp-modal-title"
        aria-describedby="otp-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="otp-modal-title" variant="h6" component="h2">
            Enter OTP
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              margin="normal"
              label="OTP"
              type='number'
              value={newOtp}
              onChange={handleChange}
              error={!!error}
              helperText={error}
            />
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </form>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleResend}
            disabled={isResendDisabled || resendCount >= 3}
            sx={{ mt: 2 }}
          >
            {isResendDisabled ? `Resend OTP (${resendTimer}s)` : 'Resend OTP'}
          </Button>
          {resendCount >= 3 && (
            <Typography color="error" sx={{ mt: 2 }}>
              Maximum resend attempts reached.
            </Typography>
          )}
          {otpSended && (
                        <Typography
                          color={"success"}
                          sx={{ mt: 2 }}
                        >
                          {otpSended}
                        </Typography>
                      )}
        </Box>
      </Modal>
    );
  };
  
  export default OtpModal;
  