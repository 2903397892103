import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    Stack,
    TextField,
    Typography,
    Card,
    CardContent,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { gql, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { IMAGES_API_URL } from "./constants";

export default function SearchBadge() {
    const { eventId } = useParams(); // Get eventId from URL params
    const { control, handleSubmit, setError, clearErrors } = useForm();

    const [searchId, setSearchId] = useState(null);
    const [certificates, setCertificates] = useState(null);
    const [showButton, setShowButton] = useState(false);
    const [role, setRole] = useState();

    const [searchCertificateFromAllRole, { loading, error }] = useMutation(gql`
    mutation searchCertificateFromAllRole($eventId: String!, $searchData: String!) {
      searchCertificateFromAllRole(eventId: $eventId, searchData: $searchData)
    }
  `);

    const handleSearch = async (variables) => {
        if (!variables.email && !variables.mobileNumber) {
            setError("formError", { message: "Please fill in at least one field." });
            return;
        }

        try {
            const searchData = variables.email || variables.mobileNumber;
            const response = await searchCertificateFromAllRole({
                variables: {
                    searchData,
                    eventId,
                },
            });

            // Parse the response data
            const parsedData = JSON.parse(response.data.searchCertificateFromAllRole);
            console.log(">>>>", parsedData)
            setCertificates(parsedData); // Set searchId from parsed response
            // setRole(role); // Set role from parsed response
            setShowButton(parsedData?.length > 0); // Show button if id exists
        } catch (err) {
            console.error("Failed to search for badge.", err);
            setCertificates(null);
        }
    };

    // const downloadUrl = `https://api-reg.eventegration.com/graphql/badge/${eventId}/${role}/${searchId}`;

    const handleDownload = (downloadUrl) => {
        window.open(downloadUrl);
        // setShowButton(null);
    };
    const Footer = () => (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 4,
            }}
        >
            <Typography variant="body2" color="textSecondary">
                Powered by{" "}
                <a
                    href="https://eventegration.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "gold", textDecoration: "none", fontWeight: "bold" }}
                >
                    Evente</a><a
                        href="https://eventegration.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#blue", textDecoration: "none", fontWeight: "bold" }}
                    >gration
                </a>
            </Typography>
        </Box>
    );

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "80vh",
                    bgcolor: "#f5f5f5",
                    p: 3,
                }}
            >
                <Card
                    sx={{
                        maxWidth: 400,
                        p: 3,
                        borderRadius: 3,
                        boxShadow: 3,
                    }}
                >
                    {!showButton ? (
                        <CardContent>
                            {eventId && eventId != "1638075500" && (
                                <img
                                    src={`${IMAGES_API_URL}logo/${eventId}.png`}
                                    width="100%"
                                    height={"100px"}
                                    style={{ objectFit: "contain" }}
                                    alt="Logo"
                                    onError={(e) => {
                                        e.target.style.display = "none";
                                    }}
                                />
                            )}
                            <Typography
                                variant="h5"
                                textAlign="center"
                                gutterBottom
                                sx={{ fontWeight: "bold", color: "#2a9d8f", mt: 2 }}
                            >
                                Search Your Certificate
                            </Typography>
                            <Typography variant="subtitle1" textAlign="center" gutterBottom>
                                Enter your registered email or mobile number to search.
                            </Typography>
                            <form
                                onSubmit={handleSubmit((data) => {
                                    clearErrors("formError");
                                    handleSearch(data);
                                })}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="email"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Email"
                                                    type="email"
                                                    fullWidth
                                                    sx={{ bgcolor: "#fff" }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider>
                                            <Chip label="OR" />
                                        </Divider>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Controller
                                            name="mobileNumber"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    label="Mobile Number"
                                                    type="number"
                                                    fullWidth
                                                    sx={{ bgcolor: "#fff" }}
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                            sx={{
                                                bgcolor: "#2a9d8f",
                                                "&:hover": { bgcolor: "#21867a" },
                                            }}
                                        >
                                            Search &nbsp;{" "}
                                            {loading && <CircularProgress color="inherit" size={20} />}
                                        </Button>
                                    </Grid>
                                    {error && (
                                        <Stack
                                            direction="row"
                                            justifyContent="center"
                                            sx={{ width: "100%" }}
                                        >
                                            <Typography textAlign="center" color="error" sx={{ mt: 2 }}>
                                                {error.message}
                                            </Typography>
                                        </Stack>
                                    )}
                                    <Grid item xs={12}>
                                        <Typography
                                            variant="body2"
                                            color="error"
                                            textAlign="center"
                                            sx={{ mt: 2 }}
                                        >
                                            {control._formState.errors?.formError?.message}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    ) : (
                        <CardContent>
                            <Stack direction="column" justifyContent="center" spacing={3}>
                                <Typography
                                    variant="h5"
                                    textAlign="center"
                                    gutterBottom
                                    sx={{ fontWeight: "bold", color: "#2a9d8f" }}
                                >
                                    Download Your Certificate
                                </Typography>
                                {certificates?.map((certificate, i) => (<Stack direction="column" key={i} spacing={2}>
                                    {/* <Typography variant="h6" textAlign="center" gutterBottom>
                                                {certificate.role}
                                            </Typography> */}
                                    <Button
                                        onClick={() => handleDownload(`https://api-reg.eventegration.com/graphql/certificate/${eventId}/${certificate.role}/${certificate.id}`)}
                                        variant="contained"
                                        color="success"
                                        fullWidth
                                    >
                                        {`Download Certificate ${certificates?.length === 1 ? "" : i + 1}`}
                                    </Button>
                                </Stack>
                                )
                                )}
                            </Stack>
                        </CardContent>
                    )}
                </Card>
            </Box>
            <Footer />
        </>
    );
}
