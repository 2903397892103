// import { useState, useEffect, useRef } from 'react';
// import { Box, CircularProgress, Stack, Typography } from '@mui/material';
// import axios from 'axios';

// const PaymobBtns = ({ amount, onPaymentSuccess, currency, description, buyerEmail, secretKey, publicKey, allData, base }) => {
//     const [errorMessage, setErrorMessage] = useState("");
//     const [loading, setLoading] = useState(false);
//     const [paymentSuccess, setPaymentSuccess] = useState(false);
//     const intervalIdRef = useRef(null);
//     const [timeLeft, setTimeLeft] = useState(300);
//     const firstNameOptions = ['Full Name', 'First Name', 'Name'];
//     const firstName = firstNameOptions.reduce((acc, key) => acc || allData?.[key], "");

//     const lastName = allData?.['Last Name'] || "nil";
//     const mobile = allData?.['Mobile'] || allData?.['Phone'] || allData?.['mobile'] || allData?.['phone'] || "nil";
//     const country = allData?.['Country'] || allData?.['country'] || "nil";

//     let client_secret, intentionId, integration, key, first_render = true;

//     useEffect(() => {
//         if (!first_render) return;
//         const performPaymentFlow = async () => {
//             try {
//                 setLoading(true);
//                 const data = await intention();
//                 client_secret = data.client_secret;
//                 intentionId = data.id;
//                 integration = data.payment_keys[0].integration;
//                 key = data.payment_keys[0].key;

//                 // Open the payment page in a new window
//                 const paymentUrl = `https://oman.paymob.com/unifiedcheckout/?publicKey=${publicKey}&clientSecret=${client_secret}`;
//                 window.open(paymentUrl, '_blank');

//                 // Start polling
//                 intervalIdRef.current = setInterval(checkPaymentStatus, 10000);
//             } catch (error) {
//                 console.error('Error performing payment flow:', error);
//                 setErrorMessage("An error occurred while processing payment. Please try again.");
//             } finally {
//                 // setLoading(false);
//             }
//         };
//         first_render = false;
//         performPaymentFlow();

//         return () => {
//             clearInterval(intervalIdRef.current);
//         };
//     }, []);

//     useEffect(() => {
//         if (timeLeft > 0 && !paymentSuccess) {
//             const timer = setInterval(() => {
//                 setTimeLeft(prevTime => prevTime - 1);
//             }, 1000);

//             return () => clearInterval(timer);
//         }
//     }, [timeLeft, paymentSuccess]);

//     const billingdata = {
//         apartment: "nil",
//         email: buyerEmail,
//         floor: "nil",
//         first_name: firstName,
//         street: "Nil",
//         building: "Nil",
//         phone_number: mobile,
//         shipping_method: "Virtual",
//         postal_code: "Nil",
//         city: "nil",
//         country: country,
//         last_name: lastName,
//         state: "Nil"
//     };
//     // console.log("intentionId",intentionId);
//     let newAmount
//     const intention = async () => {
//         try {
//             const url = "https://oman.paymob.com/v1/intention/";
//             const headers = {
//                 "authorization": `Token ${secretKey}`
//             };
// if (currency === "OMR") {
//     newAmount = amount * 1000;
// }
// else {
//     newAmount = amount;

// }
//             const data = {
//                 amount: newAmount,
//                 currency: currency,
//                 payment_methods: JSON.parse(base),
//                 items: [
//                     {
//                         name: description,
//                         amount: newAmount,
//                         description: description,
//                         quantity: 1
//                     }
//                 ],
//                 billing_data: billingdata,
//                 customer: {
//                     first_name: firstName,
//                     last_name: lastName,
//                     email: buyerEmail,
//                 },
//                 integrations: base,
//             };
//             const response = await axios.post(url, data, { headers });
//             return response?.data;
//         } catch (error) {
//             console.error('Error authenticating:', error.response.data);
//             throw error;
//         }
//     };

//     const checkPaymentStatus = async () => {
//         try {
//             if (intentionId) {
//                 const response = await axios.get(`https://api-admin.eventegration.com/graphql/checkpaymentId/${intentionId}`);
//                 const status = response?.data?.status;
//                 // console.log(status);
//                 if (status === 'success') {
//                     setPaymentSuccess(true);
//                     const details = { product_name: description, amount: `${amount} ${currency}`, payment_id: intentionId, paymentMethod: "Paymob", status: "success", email: buyerEmail, currency: currency, invoice: intentionId }
//                     const actions = { status: "COMPLETED" }
//                     setLoading(false);
//                     clearInterval(intervalIdRef.current);
//                     onPaymentSuccess(details, actions);
//                 } else if (status === 'failed') {
//                     setErrorMessage("Payment failed. Please try again.");
//                     clearInterval(intervalIdRef.current);
//                 }
//             }
//         } catch (error) {
//             console.error('Error checking payment status:', error);
//         }
//     };

//     return (
//         <>
//             {loading && <Box sx={{
//                 width: "100%",
//                 height: "100vh",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     // backgroundColor: "#f0f0f0", // background color
//     borderRadius: "8px", // border radius
//     padding: "20px", // padding
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // shadow
// }}>
//     <Stack sx={{
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "center",
//     alignItems: "center",
//     backgroundColor: "#f0f0f0", // background color
//     borderRadius: "8px", // border radius
//     padding: "20px", // padding
//     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // shadow
// }}>

//     <CircularProgress color="success" size={36} />
//     <Typography sx={{ marginBottom: "10px", marginTop: "20px", fontSize: "18px", fontWeight: "bold" }}>Please wait while we process your payment...</Typography>
//     <Typography sx={{ fontSize: "22px", color: "#666" }}>Time left: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}</Typography>
//     </Stack>
// </Box>
// }
//             {errorMessage && <Typography sx={{ color: "red" }}>{errorMessage}</Typography>}
//             {paymentSuccess && <Typography variant="h6" color="primary">Payment Successful!</Typography>}
//         </>
//     );
// };

// export default PaymobBtns;

import { useState, useEffect, useRef } from "react";
import { Box, CircularProgress, Typography, Stack } from "@mui/material";
import axios from "axios";

const PaymobBtns = ({
  amount,
  onPaymentSuccess,
  currency,
  description,
  buyerEmail,
  secretKey,
  publicKey,
  allData,
  base,
  setErrorInPaymentInitialization,
  setIsPopupNotOpened,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const intervalIdRef = useRef(null);
  const paymentWindowRef = useRef(null);
  const first_renderRef = useRef(true);
  const client_secretRef = useRef(null);
  const intentionIdRef = useRef(null);
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes
  const firstNameOptions = ["Full Name", "First Name", "Name"];
  const firstName = firstNameOptions.reduce(
    (acc, key) => acc || allData?.[key],
    ""
  );
  const lastName = allData?.["Last Name"] || "nil";
  const mobile =
    allData?.["Mobile"] ||
    allData?.["Phone"] ||
    allData?.["mobile"] ||
    allData?.["phone"] ||
    "nil";
  const country = allData?.["Country"] || allData?.["country"] || "nil";

  useEffect(() => {
    if (!first_renderRef.current || paymentSuccess || errorMessage) return;

    const performPaymentFlow = async () => {
      try {
        setLoading(true);
        const data = await intention();
        client_secretRef.current = data.client_secret;
        intentionIdRef.current = data.id;

        // Open the payment page in a new window
        const paymentUrl = `https://oman.paymob.com/unifiedcheckout/?publicKey=${publicKey}&clientSecret=${client_secretRef.current}`;
        const newWindow = window.open(paymentUrl, "_blank");

        // Check if the popup window was blocked
        if (
          !newWindow ||
          newWindow.closed ||
          typeof newWindow.closed === "undefined"
        ) {
          setIsPopupNotOpened(true);
          setErrorMessage(
            "Please allow pop-ups for this website to proceed with the payment."
          );
          setLoading(false);
          return;
        }

        // Start polling
        intervalIdRef.current = setInterval(checkPaymentStatus, 7000);

        paymentWindowRef.current = newWindow;
      } catch (error) {
        console.error("Error performing payment flow:", error);
        setErrorInPaymentInitialization(true);
      } finally {
        // setLoading(false);
      }
    };

    first_renderRef.current = false;
    performPaymentFlow();

    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [paymentSuccess, errorMessage]);

  useEffect(() => {
    if (timeLeft > 0 && !paymentSuccess) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer);
    }

    if (timeLeft === 0) {
      // Trigger failure action if time left is 0
      const details = {
        product_name: description,
        amount: amount,
        payment_id: intentionIdRef.current,
        paymentMethod: "Paymob",
        status: "failed",
        email: buyerEmail,
        currency: currency,
        invoice: intentionIdRef.current,
      };
      const actions = { payment_status: "FAILED" };
      onPaymentSuccess(details, actions);

      // Close payment window if it exists
      if (paymentWindowRef.current) {
        paymentWindowRef.current.close();
      }

      // Clear interval
      clearInterval(intervalIdRef.current);
    }
  }, [timeLeft, paymentSuccess]);
  const billingdata = {
    apartment: "nil",
    email: buyerEmail,
    floor: "nil",
    first_name: firstName,
    street: "Nil",
    building: "Nil",
    phone_number: mobile,
    shipping_method: "Virtual",
    postal_code: "Nil",
    city: "nil",
    country: country,
    last_name: lastName,
    state: "Nil",
  };
  let newAmount;
  const intention = async () => {
    try {
      const url = "https://oman.paymob.com/v1/intention/";
      const headers = {
        authorization: `Token ${secretKey}`,
      };
      if (currency === "OMR") {
        newAmount = amount * 1000;
      } else {
        newAmount = amount;
      }
      const data = {
        amount: newAmount,
        currency: currency,
        payment_methods: JSON.parse(base),
        items: [
          {
            name: description,
            amount: newAmount,
            description: description,
            quantity: 1,
          },
        ],
        billing_data: billingdata,
        customer: {
          first_name: firstName,
          last_name: lastName,
          email: buyerEmail,
        },
        integrations: base,
      };
      const response = await axios.post(url, data, { headers });
      return response?.data;
    } catch (error) {
      console.error("Error authenticating:", error.response.data);
      throw error;
    }
  };

  const checkPaymentStatus = async () => {
    try {
      if (intentionIdRef.current) {
        const response = await axios.get(
          `https://api-admin.eventegration.com/graphql/checkpaymentId/${intentionIdRef.current}`
        );
        const status = response?.data?.status;
        if (status === "success") {
          setPaymentSuccess(true);
          const details = {
            product_name: description,
            amount: amount,
            payment_id: intentionIdRef.current,
            paymentMethod: "Paymob",
            payment_status: "success",
            email: buyerEmail,
            currency: currency,
            invoice: intentionIdRef.current,
          };
          const actions = { payment_status: "COMPLETED" };
          setLoading(false);
          clearInterval(intervalIdRef.current);
          onPaymentSuccess(details, actions);
          if (paymentWindowRef.current) {
            paymentWindowRef.current.close();
          }
        } else if (status === "failed") {
          setErrorMessage("Payment failed. Please try again.");
          clearInterval(intervalIdRef.current);
          const details = {
            product_name: description,
            amount: amount,
            payment_id: intentionIdRef.current,
            paymentMethod: "Paymob",
            payment_status: "failed",
            email: buyerEmail,
            currency: currency,
            invoice: intentionIdRef.current,
          };
          const actions = { payment_status: "FAILED" };
          onPaymentSuccess(details, actions);
          if (paymentWindowRef.current) {
            paymentWindowRef.current.close();
          }
        }
      }
    } catch (error) {
      console.error("Error checking payment status:", error);
    }
  };

  return (
    <>
      {loading && !errorMessage && (
        <Box
          sx={{
            width: "100%",
            height: "80vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "8px",
            padding: "20px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            overflow: "hidden",
          }}
        >
          <Stack
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#f0f0f0",
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            <CircularProgress color="success" size={36} />
            <Typography
              sx={{
                marginBottom: "10px",
                marginTop: "20px",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              Please wait while we process your payment...
            </Typography>
            <Typography sx={{ fontSize: "22px", color: "#666" }}>
              Time left: {Math.floor(timeLeft / 60)}:
              {(timeLeft % 60).toString().padStart(2, "0")}
            </Typography>
          </Stack>
        </Box>
      )}
      {errorMessage && (
        <Typography sx={{ color: "red" }}>{errorMessage}</Typography>
      )}
      {paymentSuccess && (
        <Typography variant="h6" color="primary">
          Payment Successful!
        </Typography>
      )}
    </>
  );
};
export default PaymobBtns;

// import { useState, useEffect, useRef } from 'react';
// import { Box, CircularProgress, Typography, Stack } from '@mui/material';
// import axios from 'axios';

// const PaymobBtns = ({ amount, onPaymentSuccess, currency, description, buyerEmail, secretKey, publicKey, allData, base,setErrorInPaymentInitialization,setIsPopupNotOpened }) => {
//     const [errorMessage, setErrorMessage] = useState("");
//     const [loading, setLoading] = useState(false);
//     const [paymentSuccess, setPaymentSuccess] = useState(false);
//     const intervalIdRef = useRef(null);
//     const paymentWindowRef = useRef(null);
//     const first_renderRef = useRef(true);
//     const client_secretRef = useRef(null);
//     const intentionIdRef = useRef(null);
//     const [timeLeft, setTimeLeft] = useState(300); // 5 minutes

//     const firstNameOptions = ['Full Name', 'First Name', 'Name'];
//     const firstName = firstNameOptions.reduce((acc, key) => acc || allData?.[key], "");

//     const lastName = allData?.['Last Name'] || "nil";
//     const mobile = allData?.['Mobile'] || allData?.['Phone'] || allData?.['mobile'] || allData?.['phone'] || "nil";
//     const country = allData?.['Country'] || allData?.['country'] || "nil";

//     useEffect(() => {
//         if (!first_renderRef.current) return;
//         const performPaymentFlow = async () => {
//             try {
//                 setLoading(true);
//                 const data = await intention();
//                 client_secretRef.current = data.client_secret;
//                 intentionIdRef.current = data.id;

//                 // Open the payment page in a new window
//                 const paymentUrl = `https://oman.paymob.com/unifiedcheckout/?publicKey=${publicKey}&clientSecret=${client_secretRef.current}`;
//                 const newWindow = window.open(paymentUrl, '_blank');

//                 // Check if the popup window was blocked
//                 if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {
//                     setIsPopupNotOpened(true);
//                     setErrorMessage("Please allow pop-ups for this website to proceed with the payment.");
//                     setLoading(false);
//                     return;
//                 }

//                 // Start polling
//                 intervalIdRef.current = setInterval(checkPaymentStatus, 7000);

//                 paymentWindowRef.current = newWindow;
//             } catch (error) {
//                 console.error('Error performing payment flow:', error);
//                 setErrorInPaymentInitialization(true);
//             } finally {
//                 // setLoading(false);
//             }
//         };
//         first_renderRef.current = false;
//         performPaymentFlow();

//         return () => {
//             clearInterval(intervalIdRef.current);
//         };
//     }, []);

//     useEffect(() => {
//         if (timeLeft > 0 && !paymentSuccess) {
//             const timer = setInterval(() => {
//                 setTimeLeft(prevTime => prevTime - 1);
//             }, 1000);

//             return () => clearInterval(timer);
//         }
//         if (timeLeft === 0) {
//             // Trigger failure action if time left is 0
//             const details = {
//                 product_name: description,
//                 amount: amount,
//                 payment_id: intentionIdRef.current,
//                 paymentMethod: "Paymob",
//                 status: "failed",
//                 email: buyerEmail,
//                 currency: currency,
//                 invoice: intentionIdRef.current
//             };
//             const actions = { payment_status: "FAILED" };
//             onPaymentSuccess(details, actions);

//             // Close payment window if it exists
//             if (paymentWindowRef.current) {
//                 paymentWindowRef.current.close();
//             }

//             // Clear interval
//             clearInterval(intervalIdRef.current);
//         }
//     }, [timeLeft, paymentSuccess]);

// const billingdata = {
//     apartment: "nil",
//     email: buyerEmail,
//     floor: "nil",
//     first_name: firstName,
//     street: "Nil",
//     building: "Nil",
//     phone_number: mobile,
//     shipping_method: "Virtual",
//     postal_code: "Nil",
//     city: "nil",
//     country: country,
//     last_name: lastName,
//     state: "Nil"
// };
//     let newAmount
//     const intention = async () => {
//         try {
//             const url = "https://oman.paymob.com/v1/intention/";
//             const headers = {
//                 "authorization": `Token ${secretKey}`
//             };
//             if (currency === "OMR") {
//                 newAmount = amount * 1000;
//             }
//             else {
//                 newAmount = amount;

//             }
//             const data = {
//                 amount: newAmount,
//                 currency: currency,
//                 payment_methods: JSON.parse(base),
//                 items: [
//                     {
//                         name: description,
//                         amount: newAmount,
//                         description: description,
//                         quantity: 1
//                     }
//                 ],
//                 billing_data: billingdata,
//                 customer: {
//                     first_name: firstName,
//                     last_name: lastName,
//                     email: buyerEmail,
//                 },
//                 integrations: base,
//             };
//             const response = await axios.post(url, data, { headers });
//             return response?.data;
//         } catch (error) {
//             console.error('Error authenticating:', error.response.data);
//             throw error;
//         }
//     };

//     const checkPaymentStatus = async () => {
//         try {
//             if (intentionIdRef.current) { // Check if intentionIdRef.current exists
//                 const response = await axios.get(`https://api-admin.eventegration.com/graphql/checkpaymentId/${intentionIdRef.current}`);
//                 const status = response?.data?.status;
//                 if (status === 'success') {
//                     setPaymentSuccess(true);
//                     const details = { product_name: description, amount: amount, payment_id: intentionIdRef.current, paymentMethod: "Paymob", payment_status: "success", email: buyerEmail, currency: currency, invoice: intentionIdRef.current }
//                     const actions = { payment_status: "COMPLETED" }
//                     setLoading(false);
//                     clearInterval(intervalIdRef.current);
//                     onPaymentSuccess(details, actions);
//                     if (paymentWindowRef.current) {
//                         paymentWindowRef.current.close();
//                     }
//                 } else if (status === 'failed') {
//                     setErrorMessage("Payment failed. Please try again.");
//                     clearInterval(intervalIdRef.current);
//                     const details = { product_name: description, amount: amount, payment_id: intentionIdRef.current, paymentMethod: "Paymob", payment_status: "failed", email: buyerEmail, currency: currency, invoice: intentionIdRef.current }
//                     const actions = { payment_status: "FAILED" }
//                     onPaymentSuccess(details, actions);
//                     if (paymentWindowRef.current) {
//                         paymentWindowRef.current.close();
//                     }
//                 }
//             }
//         } catch (error) {
//             console.error('Error checking payment status:', error);
//         }
//     };

//     return (
//         <>
//             {loading && !errorMessage && <Box sx={{
//                 width: "100%",
//                 height: "80vh",
//                 display: "flex",
//                 flexDirection: "column",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 borderRadius: "8px", // border radius
//                 padding: "20px", // padding
//                 boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // shadow
//                 overflow:"hidden"
//             }}>
//                 <Stack sx={{
//                     display: "flex",
//                     flexDirection: "column",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     backgroundColor: "#f0f0f0",
//                     borderRadius: "8px", // border radius
//                     padding: "20px", // padding
//                     boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // shadow
//                 }}>
//                     <CircularProgress color="success" size={36} />
//                     <Typography sx={{ marginBottom: "10px", marginTop: "20px", fontSize: "18px", fontWeight: "bold" }}>Please wait while we process your payment...</Typography>
//                     <Typography sx={{ fontSize: "22px", color: "#666" }}>Time left: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}</Typography>
//                 </Stack>
//             </Box>}
//             {errorMessage && <Typography sx={{ color: "red" }}>{errorMessage}</Typography>}
//             {paymentSuccess && <Typography variant="h6" color="primary">Payment Successful!</Typography>}
//         </>
//     );
// };

// export default PaymobBtns;
