import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { API_URL, IMAGES_API_URL } from "./constants";
import axios from "axios";

export default function MobileOtpBadgeDownload() {
  const { eventId, mobile, role, id } = useParams();
  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [otp, setOtp] = useState(null);
  const [showButton, setShowButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpError, setOtpError] = useState(""); // For OTP sending errors
  const [otpSended, setOtpSended] = useState(""); // For OTP sending errors

  const downloadUrl = `https://api-reg.eventegration.com/graphql/badge/${eventId}/${role}/${id}`;

  useEffect(() => {
    if (eventId && mobile && role && id) {
      // Generate OTP
      const generatedOtp = Math.floor(10000 + Math.random() * 90000);
      setOtp(generatedOtp);

      // Send OTP via SMS
      setLoading(true);
      axios
        .get(`http://localhost:4005/graphql/send-sms`, {
          params: {
            mobilenumber: mobile,
            message: `Your OTP for downloading the badge is ${generatedOtp}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setOtpSended(`OTP sent successfully to ${mobile}.`);
            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error sending OTP:", error);
        });

      
      // axios
      //   .get(`${API_URL}send-sms`, {
      //     params: {
      //       mobilenumber: mobile,
      //       message: `Your OTP for downloading the badge is ${generatedOtp}`,
      //     },
      //   })
      //   .then((response) => {
      //     console.log("SMS sent:", response.data);
      //   })
      //   .catch((error) => {
      //     console.error("Error sending OTP:", error);
      //   });

    }
  }, [eventId, mobile, role, id]);

  const handleValidate = async (variables) => {
    console.log("Form data:", variables);

    if (!variables.otp || variables.otp.length !== 5) {
      setError("otp", { message: "Please enter a valid 5-digit OTP" });
      return;
    }

    if (parseInt(variables.otp, 10) !== otp) {
      setError("otp", { message: "Invalid OTP. Please try again." });
      return;
    }

    clearErrors("otp");
    setShowButton(true);
  };

  const handleDownload = () => {
    window.open(downloadUrl, "_blank");
    setShowButton(false);
  };

  if (!eventId || !mobile || !role || !id) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          bgcolor: "#f5f5f5",
          p: 3,
        }}
      >
        <Card
          sx={{
            maxWidth: 400,
            p: 3,
            borderRadius: 3,
            boxShadow: 3,
            textAlign: "center",
          }}
        >
          <Typography
            variant="h5"
            color="error"
            gutterBottom
            sx={{ fontWeight: "bold" }}
          >
            Invalid Link
          </Typography>
          <Typography variant="body1" color="textSecondary">
            The link you followed is incorrect or missing the required event
            information. Please check and try again.
          </Typography>
        </Card>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        bgcolor: "#f5f5f5",
        p: 3,
      }}
    >
      <Card
        sx={{
          maxWidth: 400,
          p: 3,
          borderRadius: 3,
          boxShadow: 3,
        }}
      >
        {!showButton ? (
          <CardContent>
            {eventId && (
              <img
                src={`${IMAGES_API_URL}logo/${eventId}.png`}
                width="100%"
                height="100px"
                style={{ objectFit: "contain" }}
                alt="Logo"
                onError={(e) => {
                  e.target.style.display = "none";
                }}
              />
            )}
            <Typography
              variant="h5"
              textAlign="center"
              gutterBottom
              sx={{ fontWeight: "bold", color: "#2a9d8f", mt: 2 }}
            >
              Verify your Mobile
            </Typography>
            <Typography variant="subtitle1" textAlign="center" gutterBottom>
              Enter the OTP sent to your mobile number.
            </Typography>
            {otpError && (
              <Typography
                variant="body2"
                color="error"
                textAlign="center"
                sx={{ mt: 2 }}
              >
                {otpError}
              </Typography>
            )}
            {otpSended && (
              <Typography
                variant="body2"
                color={"success"}
                textAlign="center"
                sx={{ mt: 2 }}
              >
                {otpSended}
              </Typography>
            )}
            <form
              onSubmit={handleSubmit((data) => {
                clearErrors("formError");
                handleValidate(data);
              })}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="otp"
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="OTP"
                        type="number"
                        fullWidth
                        sx={{ bgcolor: "#fff" }}
                        error={!!errors.otp}
                        helperText={errors?.otp?.message || ""}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{
                      bgcolor: "#2a9d8f",
                      "&:hover": { bgcolor: "#21867a" },
                    }}
                    disabled={loading}
                  >
                    {loading ? "Sending OTP..." : "Submit"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        ) : (
          <CardContent>
            <Stack direction="column" justifyContent="center" spacing={3}>
              <Typography
                variant="h5"
                textAlign="center"
                gutterBottom
                sx={{ fontWeight: "bold", color: "#2a9d8f" }}
              >
                Download Your Badge
              </Typography>
              <Button
                onClick={handleDownload}
                variant="contained"
                color="success"
                fullWidth
              >
                Download Badge
              </Button>
            </Stack>
          </CardContent>
        )}
      </Card>
    </Box>
  );
}
