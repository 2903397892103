import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GlobalStyles, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import RegistrationPage from "./RegistrationPage";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { API_URL } from "./constants";
import ContactPage from "./ContactPage";
import RegistrationIframe from "./RegistrationIframe";
import EditRegistration from "./EditRegistration";
import PaymentStatusPage from "./PaymentStatusPage";
import AgendaPreview from "./AgendaPreview";
import NotFound from "./Components/NotFound";
import LinkNotFound from "./Components/LinkNotFound";
import SurveyForm from "./SurveyForm";
import SearchBadge from "./SearchBadge";
import SearchCertificate from "./SearchCertificate";
import MobileOtpBadgeDownload from "./MobileOtpBadgeDownload";

const theme = createTheme();
const client = new ApolloClient({
  uri: API_URL,
  cache: new InMemoryCache(),
});

export default function App() {
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{
            "*::-webkit-scrollbar": {
              width: 20,
            },
            "*::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "*::-webkit-scrollbar-thumb": {
              backgroundColor: "#d6dee1",
              borderRadius: 20,
              border: "6px solid transparent",
              backgroundClip: "content-box",
            },
            "*::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "#a8bbbf",
            },
          }}
        />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Website />} />
            {/* <Route path="/" element={<NotFound />} /> */}
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/paymentstatus" element={<PaymentStatusPage />} />
            <Route path="/:link" element={<RegistrationPage />} />
            <Route path="/:link/:id/edit" element={<EditRegistration />} />
            <Route path="/iframe/:link" element={<RegistrationIframe />} />
            <Route path="/agenda/:link" element={<AgendaPreview />} />
            <Route path="/survey/:link" element={<SurveyForm />} />
            <Route path="/search/:eventId" element={<SearchBadge />} />
            <Route path="/verification-badge/:eventId/:mobile/:role/:id" element={<MobileOtpBadgeDownload />} />
            <Route path="/cert/:eventId" element={<SearchCertificate />} />

            <Route path="*" element={<LinkNotFound />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  );
}

function Website() {
  // 👇️ redirect to external URL
  window.location.replace("https://eventegration.com/website/index.php");

  return null;
}
